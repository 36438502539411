var render = function render(){var _vm=this,_c=_vm._self._c;return _c('new-drop-down',{attrs:{"placement":"right","buttonType":"custom","buttonClasses":"flex items-center text-main-color-100 text-sm","data-cy":"compose-delay-tweets"},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.getDelayBetweenTweets)+" delay "),_c('span',{staticClass:"ml-1 hidden sm:inline"},[_vm._v("between posts")]),_c('inline-svg',{staticClass:"ml-2 h-2.5 w-2.5 stroke-current",class:['transform', slotProps.isOpen ? 'rotate-180' : 'rotate-0'],attrs:{"src":"/img/icons/arrow-down-icon.svg"}})]}}])},[[_c('div',{staticClass:"flex flex-col items-start justify-center gap-y-2"},[_vm._l((_vm.dropdownValues),function(item,index){return [(index !== _vm.dropdownValues.length - 1)?_c('new-button',{key:item.label,staticClass:"dark-mode-text-white text-main-color-100",attrs:{"data-cy":"compose-delay-option","type":"custom"},on:{"click":function($event){return _vm.setDelayBetweenTweets(item.value, false)}}},[_c('span',{staticClass:"outline-blue mr-3.5 inline-block h-2.5 w-2.5 rounded-full",class:{
              'bg-main-color-100': _vm.delayBetweenTweets === item.value && !_vm.isCustomValue,
            }}),_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),(index === _vm.dropdownValues.length - 1)?_c('div',{key:item.label,staticClass:"flex w-full flex-col gap-y-2"},[_c('hr',{staticClass:"dropdown-separator"}),_c('div',{staticClass:"flex w-full items-center"},[_c('new-button',{attrs:{"data-cy":"compose-delay-option","type":"custom"},on:{"click":_vm.setCustomDelayBetweenTweets}},[_c('span',{staticClass:"outline-blue mr-1 inline-block h-2.5 w-2.5 rounded-full",class:{ 'bg-main-color-100': _vm.isCustomValue }})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.value),expression:"item.value"}],staticClass:"dark-mode-text-white w-full border-0 text-main-color-100 sm:w-32",attrs:{"type":"number","min":"0","placeholder":"Custom delay..."},domProps:{"value":(item.value)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(item, "value", $event.target.value)},_vm.setCustomDelayBetweenTweets]}}),_c('div',{staticClass:"flex rounded-md border-2 border-main-color-30 p-0.5"},[_c('new-button',{attrs:{"type":"custom"},on:{"click":function($event){return _vm.setCustomDelayUnit('m')}}},[_c('span',{staticClass:"rounded w-6 py-0.5 leading-4",class:{
                    'bg-main-color-100 text-white': _vm.customDelayUnit === 'm',
                    'bg-transparent text-main-color-30': _vm.customDelayUnit !== 'm',
                  }},[_vm._v("M")])]),_c('new-button',{attrs:{"type":"custom"},on:{"click":function($event){return _vm.setCustomDelayUnit('hr')}}},[_c('span',{staticClass:"rounded ml-1 w-6 py-0.5 leading-4",class:{
                    'bg-main-color-100 text-white': _vm.customDelayUnit === 'hr',
                    'bg-transparent text-main-color-30': _vm.customDelayUnit !== 'hr',
                  }},[_vm._v("Hr")])])],1)],1)]):_vm._e()]})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }