<template>
  <!-- Mobile screen -->
  <div v-if="isMobile" class="flex flex-col items-center gap-10 py-6">
    <template v-if="!showCheckout">
      <p class="text-3xl font-bold text-ds-text-primary">Based on your profile, we recommend...</p>
      <div class="flex w-full flex-col items-center gap-5">
        <toggle-input
          :toggle="isSubscriptionTypeMonthly"
          @toggle-interval="changeSubscriptionType"
        />
        <div class="flex w-full flex-col items-center gap-10">
          <div class="flex w-full flex-col items-center gap-5">
            <plan-card
              :currentPlan="recommendedPlan"
              :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
              @checkout="openCheckout(recommendedPlan.planId)"
            />
            <new-button class="w-full" size="lg" @click="openCheckout(recommendedPlan.planId)">
              Start 7-days free trail
            </new-button>
          </div>
          <div class="flex flex-col gap-3">
            <p class="text-xl font-bold text-ds-text-primary">Other plans:</p>
            <plan-card
              v-for="plan in plansWithoutRecommended"
              :key="plan.name"
              :currentPlan="plan"
              :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
              @checkout="openCheckout(plan.planId)"
            />
          </div>
        </div>
        <span>
          <new-button class="w-full" size="lg" type="secondary" @click="openComparePlan">
            Compare plans
          </new-button>
        </span>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col gap-4">
        <span class="flex gap-5">
          <button @click="closeCheckout">
            <inline-svg
              style="rotate: 180deg"
              class="h-5 w-5 stroke-current text-primary"
              src="/img/icons/new/arrow.svg"
            />
          </button>
          <p class="text-3xl font-bold text-ds-text-primary">Try Hypefury free for 7 days</p>
        </span>
        <p class="text-lg font-semi-medium text-secondary">You can cancel at any time</p>
      </div>
      <div class="checkout-container rounded-xl bg-white p-4" />
      <trial-timeline :followersCount="userProfile.followersCount" />
    </template>

    <compare-plans-modal
      class="text-ds-text-primary"
      :showPlansModal="showPlansModal"
      :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
      :selectedSubscriptionType="selectedSubscriptionType"
      :planNames="plansNamesToDisplay"
      @changeSubscriptionType="changeSubscriptionType"
      @close="showPlansModal = false"
    />
  </div>

  <!-- Desktop screen -->
  <div class="flex flex-col items-center gap-10 p-10" v-else>
    <template v-if="!showCheckout">
      <p class="text-4xl font-bold text-ds-text-primary">Based on your profile, we recommend...</p>
      <div class="flex w-full flex-col items-center gap-6">
        <toggle-input
          :toggle="isSubscriptionTypeMonthly"
          @toggle-interval="changeSubscriptionType"
        />
        <div v-if="!showAllPlans" class="flex w-full justify-center gap-10 lg:gap-32">
          <div class="hidden flex-1 xl:inline-block" />
          <div>
            <plan-card
              :currentPlan="recommendedPlan"
              :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
              :showSelectButton="!showAllPlans"
              @checkout="openCheckout(recommendedPlan.planId)"
            />
          </div>
          <trial-timeline class="xl:flex-1" :followersCount="userProfile.followersCount" />
        </div>
        <div v-else class="flex flex-wrap items-center justify-center gap-3">
          <plan-card
            v-for="plan in plansToDisplay"
            :key="plan.name"
            :currentPlan="plan"
            :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
            @checkout="openCheckout(plan.planId)"
            @open-compare-plans="showPlansModal = true"
          />
        </div>
        <span v-if="!showAllPlans">
          <new-button class="w-full" size="md" type="secondary" @click="showAllPlans = true">
            See other plans
          </new-button>
        </span>
      </div>
      <compare-plans-modal
        class="text-ds-text-primary"
        :showPlansModal="showPlansModal"
        :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
        :selectedSubscriptionType="selectedSubscriptionType"
        :planNames="plansNamesToDisplay"
        @changeSubscriptionType="changeSubscriptionType"
        @close="showPlansModal = false"
        @checkout="openCheckout"
      />
    </template>
    <template v-else>
      <div class="flex flex-col items-center gap-4">
        <span class="flex items-center gap-5">
          <button @click="closeCheckout">
            <inline-svg
              style="rotate: 180deg"
              class="h-5 w-5 stroke-current text-primary"
              src="/img/icons/new/arrow.svg"
            />
          </button>
          <p class="text-3xl font-bold text-ds-text-primary">Try Hypefury free for 7 days</p>
        </span>
        <p class="text-lg font-semi-medium text-secondary">You can cancel at any time</p>
      </div>
      <div class="flex w-full justify-center gap-10 lg:gap-32">
        <div class="hidden flex-1 xl:inline-block" />
        <div class="checkout-container w-466 rounded-xl bg-white p-10" />
        <trial-timeline class="xl:flex-1" :followersCount="userProfile.followersCount" />
      </div>
    </template>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters, mapState } from 'vuex';
  import PlanCard from '@/components/Onboarding/PlanCard.vue';
  import MobileMixin from '../../views/Mixins/MobileMixin.vue';
  import TrialTimeline from '@/components/Onboarding/TrialTimeline.vue';
  import { PLANS } from '../../../functions/src/util/customerStatus.cjs';
  import ComparePlansModal from '@/components/Onboarding/ComparePlansModal.vue';
  import ToggleInput from '@/components/Onboarding/ToggleSubscriptionInterval.vue';
  import * as config from '@/config';
  import { v4 as publicIpv4 } from 'public-ip';
  import CustomerCountryMixin from '@/views/Mixins/CustomerCountryMixin.vue';
  import controller from '@/controller';
  import dao from '@/dao';

  export default {
    name: 'on-boarding',
    components: {
      PlanCard,
      ToggleInput,
      TrialTimeline,
      ComparePlansModal,
    },
    mixins: [MobileMixin, CustomerCountryMixin],
    data() {
      return {
        yearlyPlans: {},
        monthlyPlans: {},
        showCheckout: false,
        showAllPlans: false,
        showPlansModal: false,
        isSubscriptionTypeMonthly: true,
        plansNamesToDisplay: ['standard', 'premium', 'business', 'agency'],
      };
    },
    computed: {
      ...mapState(['isPaddleReady']),
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
      recommendedPlanName() {
        return this.userProfile.followersCount < 1_000 ? 'standard' : 'premium';
      },
      selectedSubscriptionType() {
        if (this.isSubscriptionTypeMonthly) {
          return this.monthlyPlans;
        }

        return this.yearlyPlans;
      },
      plansArray() {
        return Object.entries(PLANS)
          .map(([key, value]) => {
            const capitalizedName = lodash.capitalize(key);
            const prices = {
              monthly: this.monthlyPlans[capitalizedName],
              yearly: this.yearlyPlans[capitalizedName],
            };

            return {
              name: key,
              prices,
              planId: this.getPlanId(key),
              ...value,
              isRecommended: key === this.recommendedPlanName,
            };
          })
          .filter((plan) => this.plansNamesToDisplay.includes(plan.name))
          .map((plan) => {
            return {
              planId: this.getPlanId(
                this.getPlanName(plan.name),
                this.isSubscriptionTypeMonthly ? 'month' : 'year',
              ),
              ...plan,
            };
          });
      },
      plansToDisplay() {
        return this.plansArray.filter((plan) => this.plansNamesToDisplay.includes(plan.name));
      },
      recommendedPlan() {
        return this.plansToDisplay.filter((plan) => plan.name === this.recommendedPlanName)[0];
      },
      plansWithoutRecommended() {
        return this.plansToDisplay.filter((plan) => plan.name !== this.recommendedPlanName);
      },
      theme() {
        const localTheme = localStorage.getItem('theme');
        return localTheme || 'dark';
      },
    },
    methods: {
      getPlanId(plan) {
        if (this.isSubscriptionTypeMonthly) {
          return config.paddleInfo.trialPlans[`${plan}Monthly`];
        }

        return config.paddleInfo.trialPlans[`${plan}Yearly`];
      },
      changeSubscriptionType(value) {
        this.isSubscriptionTypeMonthly = value;
      },
      openCheckout(planId) {
        this.showPlansModal = false;
        this.showCheckout = true;

        this.$nextTick(() => {
          const container = document.querySelector('.checkout-container');

          if (!container) return;

          window.Paddle.Checkout.open({
            product: planId,
            method: 'inline',
            frameStyle: 'width: 100%; background-color: transparent; border: none;',
            allowQuantity: false,
            passthrough: JSON.stringify({ userId: this.parentUserProfile.uid }),
            frameTarget: 'checkout-container',
            frameInitialHeight: '450',
            displayModeTheme: 'light',
          });
        });
      },
      closeCheckout() {
        this.showCheckout = false;

        window.Paddle.Checkout.close();
      },
      getPlanName(plan) {
        switch (plan) {
          case 'standard':
            return 'starter';
          case 'premium':
            return 'creator';
          default:
            return plan;
        }
      },
      openComparePlan() {
        this.showPlansModal = true;
      },
      loadPaddle() {
        if (!window.Paddle) return;
        if (!config.isEnvProd) {
          window.Paddle.Environment.set('sandbox');
        }

        window.Paddle.Setup({
          vendor: config.paddleInfo.vendorId,
          eventCallback: async (data) => {
            const eventData = data.eventData;
            if (data.event === 'Checkout.PaymentComplete') {
              // First promoter integration
              window.fpr &&
                window.fpr('referral', {
                  email: eventData.user.email,
                  uid: eventData.user.id,
                });
            }

            if (data.event === 'Checkout.Complete') {
              window.dataLayer = window.dataLayer || [];
              // eslint-disable-next-line no-inner-declarations, prettier/prettier, no-undef
              function gtag(){dataLayer.push(arguments)};
              gtag('event', 'purchase', {
                transaction_id: data.eventData.checkout.id,
                value: data.eventData.checkout.prices.customer.total,
                currency: data.eventData.checkout.prices.customer.currency,
              });
            }
          },
        });
      },
      async getPlansDetails() {
        try {
          const customerIp = await publicIpv4();
          const plansDetails = await controller.getPlansPrices(this.currentUser, customerIp);
          const monthly = {};
          const yearly = {};

          plansDetails.forEach((plan) => {
            const [title, type] = plan.product_title.split(' ');
            if (type === 'monthly') {
              monthly[title] = {
                ...plan,
                displayPrice: `${plan.price.net} ${plan.currency}`,
              };
            } else if (type === 'yearly') {
              yearly[title] = {
                ...plan,
                displayPrice: `${plan.price.net} ${plan.currency}`,
              };
            }
          });

          this.yearlyPlans = yearly;
          this.monthlyPlans = monthly;
        } catch (error) {
          console.error(`An error occurred while trying to fetch the plans details:`, error);
        }
      },
    },
    watch: {
      parentUserProfile(newVal, oldVal) {
        if (!oldVal.subscriptionId && newVal.subscriptionId) {
          this.$router.push('/queue');
          dao.userProfile.removeOnboardingStep(this.userProfile.uid);
        }
      },
      isPaddleReady: {
        handler(val) {
          if (val) {
            this.loadPaddle();
            this.getPlansDetails();
          }
        },
        immediate: true,
      },
    },
  };
</script>
