<template>
  <div class="flex flex-col gap-3">
    <p class="text-lg font-medium text-secondary">How your trial works</p>
    <ul class="m-0 flex list-none flex-col p-0">
      <li v-for="(item, index) in timelineItems" :key="index">
        <div class="flex gap-3">
          <div class="flex flex-col items-center">
            <span
              class="flex items-center justify-center rounded-full bg-ds-button-secondary-background p-1.5"
            >
              <inline-svg
                class="h-5 w-5 stroke-ds-button-icon text-ds-button-icon"
                :src="item.icon"
              />
            </span>
            <span
              v-if="index !== timelineItems.length - 1"
              class="w-1 flex-1 bg-ds-button-secondary-background"
            />
          </div>
          <div class="flex flex-col gap-1 pb-6 text-sm font-medium text-primary">
            <p>{{ item.day }}: {{ item.title }}</p>
            <p v-if="item.description" class="font-semi-medium text-ds-text-secondary-tertiary">
              {{ item.description }}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'trial-timeline',
    props: {
      followersCount: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {};
    },
    computed: {
      timelineItems() {
        const nowPlusSevenDays = moment().add(7, 'days').format('MMM DD');

        const timelineItemsBellow1kFollowers = [
          {
            day: 'Today',
            icon: '/img/icons/new/autoplug.svg',
            title: 'Instant, free access',
            description: 'Start exploring Hypefury and supercharge your social media growth.',
          },
          {
            day: 'Day 1',
            title: 'Secret Checklist To Grow on 𝕏',
            icon: '/img/icons/new/star.svg',
            description: 'A tailored guide designed for accounts with fewer than 2,000 followers.',
          },
          {
            day: 'Day 3',
            title: 'How the 𝕏 Algorithm Prioritizes Content',
            icon: '/img/icons/new/trending.svg',
            description: 'Access a limited-time masterclass by Dagobert Renouf.',
          },
          {
            day: 'Day 5',
            title: 'Real World Writing',
            icon: '/img/icons/new/edit.svg',
            description: 'Learn how to become persuasive with words in a masterclass by Jay Yang.',
          },
          {
            day: 'Day 6',
            title: 'Add $5,000/Month to Your Biz (Even With Little Traffic)',
            icon: '/img/icons/new/chart.svg',
            description: 'Gain insights from a limited-access masterclass by Jon Brosio.',
          },
          {
            day: 'Day 6',
            title: 'Email Reminder',
            icon: '/img/icons/new/bell.svg',
            description: 'We’ll send you a helpful email to remind you when your trial is ending.',
          },
          {
            day: 'Day 7',
            title: 'Subscription begins',
            icon: '/img/icons/new/check-simple.svg',
            description: `Your payment method will be charged on ${nowPlusSevenDays}.`,
          },
        ];
        const timelineItemsOver1kFollowers = [
          {
            day: 'Today',
            icon: '/img/icons/new/autoplug.svg',
            title: 'Instant, free access',
            description: 'Start exploring Hypefury and supercharge your social media growth.',
          },
          {
            day: 'Day 1',
            title: 'Viral Hooks Archive',
            icon: '/img/icons/new/star.svg',
            description: "You can't go viral without the right hook.",
          },
          {
            day: 'Day 3',
            title: 'How the 𝕏 Algorithm Prioritizes Content',
            icon: '/img/icons/new/trending.svg',
            description: 'Limited-access masterclass by Dagobert Renouf.',
          },
          {
            day: 'Day 5',
            title: 'Cold DM Your Offer in 15 Minutes',
            icon: '/img/icons/new/edit.svg',
            description: "Yannick Veys' most effective tactic to sell on 𝕏.",
          },
          {
            day: 'Day 6',
            title: 'Content That Sells',
            icon: '/img/icons/new/chart.svg',
            description: 'Limited-access masterclass by Jose Rosado.',
          },
          {
            day: 'Day 6',
            title: 'Email Reminder',
            icon: '/img/icons/new/bell.svg',
            description: 'We’ll send you a helpful email to remind you when your trial is ending.',
          },
          {
            day: 'Day 7',
            title: 'Subscription begins',
            icon: '/img/icons/new/check-simple.svg',
            description: `Your payment method will be charged on ${nowPlusSevenDays}.`,
          },
        ];
        return this.followersCount < 1000
          ? timelineItemsBellow1kFollowers
          : timelineItemsOver1kFollowers;
      },
    },
  };
</script>
