<template>
  <onboarding-layout
    :leftBg="['bg-blue-20 dark-mode:bg-dark-mode-80', onboardingStep === 8 ? 'md:hidden' : '']"
    :rightBg="[onboardingStep === 8 ? 'bg-ds-background' : 'dark-mode:bg-dark-mode-90']"
    :analyzing="analyzing"
    :show-logo="onboardingStep === 0 && !analyzing"
    :show-steps="!analyzing && onboardingStep !== 0"
  >
    <template v-slot:left-content>
      <div class="h-full flex items-center flex-col justify-center" v-if="onboardingStep !== 7">
        <loading-logo />
        <div class="text-center">
          <h1 class="text-32 lg:text-56 font-bold leading-10 lg:leading-70 my-6 lg:my-10 dark-mode:text-white">{{ leftPanelTitle }}</h1>
          <p class="text-base lg:text-lg text-gray-70 mb-6 lg:mb-10 dark-mode:text-dark-mode-10">{{ leftPanelSubTitle }}</p>
        </div>
      </div>
      <div
        class="flex h-full flex-col items-center justify-center bg-success-50 dark-mode:bg-dark-mode-80"
        v-if="onboardingStep === 7"
        style="margin: 0 -24px"
      >
        <success-logo />
        <div class="text-center">
          <h1 class="text-32 lg:text-56 font-bold leading-10 lg:leading-70 my-6 lg:my-10 dark-mode:text-white">Account analyzed</h1>
          <p class="text-lg text-gray-70 mb-10 dark-mode:text-dark-mode-10">Your account is ready to grow. </p>
        </div>
      </div>
    </template>
    <template v-slot:right-content>
      <div
        v-if="onboardingStep !== 7 && onboardingStep !== 8"
        class="bg-main-color-50 p-6 md:hidden dark-mode:bg-dark-mode-80"
        style="margin: 0 -24px"
      >
        <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
      </div>
      <div v-else-if="onboardingStep !== 8" class="bg-success-50 p-6 sticky top-0 z-10 md:hidden" style="margin: 0 -24px">
        <p class="text-lg leading-23">Your account has been analyzed and is ready to grow! 🚀</p>
      </div>
      <div
        class="absolute bottom-0 left-0 z-10 flex h-40 w-full items-center justify-center bg-white px-6 pb-10 pt-6 shadow-steps md:bottom-auto md:h-auto md:pb-6 md:pt-6 md:shadow-none dark-mode:bg-dark-mode-90"
        v-if="!analyzing && onboardingStep !== 8"
      >
        <div class="grid w-full gap-y-4">
          <div class="grid w-full gap-4 md:hidden">
            <template v-for="step in steps">
              <new-button
                :key="step"
                v-if="onboardingStep === 0 && step === 1"
                @click.prevent="saveUserDetails"
                :disabled="!isEmailAddressValid || isSavingUserDetails"
              >
                This is correct
              </new-button>
              <new-button
                :key="step"
                v-if="onboardingStep === 1 && step === 2"
                @click.prevent="nextStep(onboardingStep)"
              >
                Next
              </new-button>
              <div
                :key="step"
                v-if="onboardingStep === 2 && step === 3"
                class="flex flex-col gap-y-4"
              >
                <new-button
                  @click.prevent="addFirstTweetToQueue(firstTweets[selectedFirstTweetIndex])"
                >
                  Add to queue
                </new-button>
                <new-button type="secondary" @click="() => onboardingStep++"> Skip </new-button>
              </div>
              <div
                :key="step"
                v-if="onboardingStep === 3 && step === 4"
                class="flex flex-col space-y-4"
              >
                <new-button
                  @click.prevent="addTweetsToQueue"
                  :disabled="addTweetsToQueueOptions.disabled"
                >
                  {{ addTweetsToQueueOptions.label }}
                </new-button>
                <new-button type="secondary" @click="() => onboardingStep++"> Skip </new-button>
              </div>
              <new-button
                :key="step"
                v-if="onboardingStep === 4 && step === 5"
                @click.prevent="saveTweetboosterSettings"
              >
                Next
              </new-button>
              <div
                :key="step"
                v-if="onboardingStep === 5 && step === 6"
                class="grid gap-x-4 gap-y-4"
              >
                <new-button
                  @click="saveWatchedUsers"
                  :disabled="isSavingWatchedUsers || watchedUsers.length === 0"
                >
                  Next
                </new-button>
                <new-button type="secondary" @click="() => (onboardingStep = onboardingStep + 2)">
                  Skip
                </new-button>
              </div>
              <new-button
                :key="step"
                v-if="onboardingStep === 6 && step === 7"
                @click="nextStep(onboardingStep)"
              >
                {{ hasUserRepliedToWatchedUser ? 'Next' : 'Skip' }}
              </new-button>
              <new-button
                :key="step"
                v-if="onboardingStep === 7 && step === 8"
                @click="viewMyQueue"
              >
                I'll keep 10 tweets in my Queue
              </new-button>
            </template>
          </div>
          <div class="flex items-center justify-center gap-2">
            <div
              v-for="step in steps"
              :key="step"
              class="h-2 w-2 rounded-lg md:w-6"
              :class="
                onboardingStep === step - 1
                  ? 'bg-main-color-100'
                  : 'bg-gray-60 dark-mode:bg-dark-mode-50'
              "
            />
          </div>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container" v-if="onboardingStep === 0">
        <div class="grid w-full gap-6 lg:w-466">
          <h3>Your details</h3>
          <div class="form-group">
            <label for="name" class="n-label">Whats your first name?</label>
            <input
              id="name"
              type="text"
              placeholder="Let’s be friends"
              class="form-field"
              v-model="providedName"
              :disabled="isSavingUserDetails"
              @keypress="saveUserDetailsOnEnter($event)"
            />
          </div>
          <div class="form-group">
            <label for="name" class="n-label"> Timezone </label>
            <select
              class="form-field"
              :options="allTimeZones"
              @input="(event) => updateTimezone(event.target.value)"
              :disabled="isSavingUserDetails"
            >
              <option
                v-for="(timezone, key) in allTimeZones"
                :key="key"
                :value="timezone"
                :selected="timezone === selectedTimeZone"
              >
                {{ timezone }}
              </option>
            </select>
            <span v-if="timezoneError" class="text-red-600 text-base flex justify-end">{{ timezoneError }}</span>
          </div>
          <div class="form-group">
            <label for="email" class="n-label"> Email </label>
            <input ref="emailAddress" id="email" type="email" placeholder="Email" class="form-field" v-model="emailAddress" :disabled="isSavingUserDetails" @keypress="saveUserDetailsOnEnter($event)" />
            <span v-if="emailError" class="text-red-600 text-base flex justify-end">{{ emailError }}</span>
          </div>
          <new-button
            class="desktop-only"
            size="lg"
            @click.prevent="saveUserDetails"
            :disabled="!isEmailAddressValid || isSavingUserDetails"
          >
            This is correct
          </new-button>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container" v-if="onboardingStep === 1">
        <div class="grid w-full gap-6 lg:w-466">
          <h3>What do you like to tweet about?</h3>
          <div
            class="flex flex-wrap gap-x-2 gap-y-4 md:gap-x-4 md:gap-y-6 overflow-y-auto"
            :class="isMobile ? '' : 'h-64'"
          >
            <div
              v-for="category in categoriesForSetup"
              :key="category.id"
              class="cursor-pointer rounded-full border-2 px-4 py-2 text-base font-medium leading-5 dark-mode:bg-dark-mode-70 dark-mode:text-white"
              :class="[
                categoriesState[category.name] === 'hypefury'
                  ? 'border-main-color-100 bg-main-color-50 text-main-color-100 dark-mode:border-main-color-100 dark-mode:text-white'
                  : 'border-gray-60 dark-mode:border-dark-mode-70',
              ]"
              @click="toggleState(category)"
            >
              <span :title="category.name" :class="categoriesState[category.name]">
                {{ category.name }}
              </span>
            </div>
          </div>
          <new-button class="desktop-only" size="lg" @click.prevent="nextStep(onboardingStep)">
            Next
          </new-button>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container" v-if="onboardingStep === 2">
        <div class="w-full lg:w-466 grid gap-8">
          <div class="mb-4 space-y-4">
            <h3>Select your first tweet</h3>
            <p class="subheading">We’ve created something for you to tweet</p>
          </div>
          <div class="w-full">
            <div class="flex items-start gap-4 w-full mb-10">
              <div class="w-12 h-12 md:w-16 md:h-16">
                <img :src="userProfile.photoURL" :alt="userProfile.username" class="rounded-full w-full h-full object-cover">
              </div>
              <div class="flex-1">
                <div class="flex items-center gap-2 mb-2">
                  <span class="font-bold text-lg dark-mode:text-white block">{{userProfile.name}}</span>
                  <span class="text-lg text-gray-80 dark-mode:text-dark-mode-10 block truncate w-16 md:w-auto">@{{userProfile.username}}</span>
                </div>
                <textarea
                  class="bg-transparent resize-none form-control none without-scrollbar"
                  rows="4"
                  disabled
                  placeholder="What would you like to say?"
                  maxlength="280"
                  :value="firstTweets[selectedFirstTweetIndex].text"
                   />
                <div v-if="firstTweets[selectedFirstTweetIndex].media.length > 0" class="h-188" style="width: 195px">
                  <img :src="firstTweets[selectedFirstTweetIndex].mediaURL" class="w-full h-full object-cover rounded-xl" />
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center">
              <div class="flex items-center gap-4 cursor-pointer">
                <span class="w-6 h-6 rounded-full dark-mode:bg-dark-mode-70 bg-gray-10 dark-mode:text-gray-70 text-dark-mode-10 flex items-center justify-center" @click="() => cycleFirstTweetIndex(false)">
                  <inline-svg src="/img/icons/left-chevron.svg"></inline-svg>
                </span>
                <span class="text-black text-lg dark-mode:text-white">Change tweet</span>
                <span class="w-6 h-6 rounded-full dark-mode:bg-dark-mode-70 bg-gray-10 dark-mode:text-gray-70 text-dark-mode-10 flex items-center justify-center" @click="() => cycleFirstTweetIndex(true)">
                  <inline-svg src="/img/icons/right-chevron.svg" />
                </span>
              </div>
            </div>
          </div>
          <div class="desktop-only-flex flex-col space-y-8 md:flex">
            <new-button size="lg" @click.prevent="addFirstTweetToQueue(firstTweets[selectedFirstTweetIndex])">
              Add to queue
            </new-button>
            <new-button type="underline" @click="() => onboardingStep++"> Skip </new-button>
          </div>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container flex-col" v-if="onboardingStep === 3">
        <div class="overflow-y-auto w-full without-scrollbar md:h-600">
          <div class="w-full lg:w-466 grid gap-6 mx-auto">
            <div class="space-y-2">
              <h3>Select your first 10 tweets to queue</h3>
              <p class="subheading">Pick from your best performing tweets</p>
              <p
                v-if="areBestTweetsTakingTooLongToLoad && (!areUsersBestTweetsFetched || !userProfile.inspirationTweetsLastIndexedAt)"
                class="text-md leading-6 text-gray-70 dark-mode:text-dark-mode-10"
              >
                It's taking a bit longer. Please hold on...
              </p>
            </div>
            <div
              v-if="userProfile.inspirationTweetsLastIndexedAt && usersBestTweets.length < 8"
            >
              <p class="text-md leading-6 text-gray-70 dark-mode:text-dark-mode-10">
                You don't have much tweets yet...
              </p>
            </div>
            <virtual-list
              v-else-if="areUsersBestTweetsFetched && userProfile.inspirationTweetsLastIndexedAt"
              :data-key="'id'"
              :data-sources="usersBestTweets"
              :data-component="itemComponent"
              :extra-props="extraProps"
              :item-class="'pb-6'"
              class="md:overflow-auto without-scrollbar md:h-480"
              :keeps="20"
              @update-tweets="updateTweetsToQueue"
            />
            <!-- <div
              v-else
              v-for="n in 4"
              v-bind:key="n"
              class="loading-tweet bg-dark rounded-lg p-3"
            >
              &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
            </div> -->
          </div>
        </div>
        <div
          class="md:border-t bottom-0 left-0 w-full border-gray-90 md:absolute md:bg-white md:py-6 dark-mode:border-dark-mode-60 dark-mode:bg-dark-mode-90"
        >
          <div
            class="mx-auto mt-8 hidden w-full flex-col space-y-6 md:mt-0 md:flex md:space-y-8 lg:w-466"
          >
            <new-button
              size="lg"
              @click.prevent="addTweetsToQueue"
              :disabled="addTweetsToQueueOptions.disabled"
            >
              {{ addTweetsToQueueOptions.label }}
            </new-button>
            <new-button type="underline" @click="() => onboardingStep++"> Skip </new-button>
          </div>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container" v-if="onboardingStep === 4">
        <div class="w-full lg:w-466 grid gap-6">
          <div class="space-y-6">
            <h3>Get 35% more engagement</h3>
            <p class="subheading">
              Let Hypefury retweet your most engaging tweets, a few hours after they get posted, to
              get even more reach
            </p>
          </div>
          <div class="form-group">
            <div class="rounded-lg px-4 h-60 flex items-center justify-between" :class="isTweetBoosterEnabled ? 'bg-success-50 dark-mode:bg-dark-mode-70' : 'bg-gray-60 dark-mode:bg-dark-mode-70'">
              <span class="text-lg font-medium dark-mode:text-white">
                Enable the tweet booster
              </span>
              <base-toggle size="lg" v-model="isTweetBoosterEnabled" />
            </div>
          </div>
          <new-button size="lg" class="desktop-only" @click.prevent="saveTweetboosterSettings"> Next </new-button>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container" v-if="onboardingStep === 5">
        <div class="grid w-full gap-6 lg:w-466">
          <div class="space-y-6">
            <h3>Engage with your favorite accounts to grow faster</h3>
            <p class="subheading">
              Hypefury has a quick and easy-to-use interface to engage with accounts in your niche.
            </p>
            <p class="subheading">Select 3 accounts:</p>
          </div>
          <div class="flex flex-wrap gap-x-2 gap-y-4 md:gap-x-4 md:gap-y-6 overflow-y-auto" style="max-height: 10rem;">
            <div
              v-for="user in suggestedWatchedUsers.filter((user) => !watchedUsers.includes(user.username) && !watchedUsers.includes(`@${user.username}`))"
              :key="user.twitterId"
              class="rounded-full text-base border-2 font-medium py-2 px-4 cursor-pointer leading-5 dark-mode:bg-dark-mode-70 dark-mode:text-white border-gray-60 dark-mode:border-dark-mode-70"
            >
              <span
                :title="user.username"
                @click="addSuggestedWatchedUser(user)"
              >
                {{ user.username }}
              </span>
            </div>
          </div>
          <div class="grid gap-4">
            <vue-tribute :options="tributeOptions" v-for="i in 3" :key="i">
              <input placeholder="@username" class="form-field" v-model="watchedUsers[i - 1]" :disabled="isSavingWatchedUsers">
            </vue-tribute>
          </div>
          <div class="desktop-only-flex flex-col space-y-8">
            <new-button
              size="lg"
              @click="saveWatchedUsers"
              :disabled="isSavingWatchedUsers || watchedUsers.length === 0"
            >
              Next
            </new-button>
            <new-button type="underline" @click="() => (onboardingStep = onboardingStep + 2)">
              Skip
            </new-button>
          </div>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container" v-if="onboardingStep === 6">
        <div class="w-full xl:w-591 grid gap-6">
          <div>
            <h3 class="text-2xl md:text-3xl font-bold leading-8 md:leading-10 dark-mode:text-white mb-6">Send your first reply</h3>
            <p class="text-lg leading-6 text-gray-70 dark-mode:text-dark-mode-10">Type a reply or simply pick a reply if you're in a hurry.</p>
          </div>
          <div class="border border-gray-90 rounded-2xl p-6 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70">
            <div class="grid gap-6">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <div class="w-8 h-8">
                    <img :src="tweetOfWatchedUser.user.profile_image_url" :alt="tweetOfWatchedUser.user.name" class="rounded-full w-full h-full object-cover" />
                  </div>
                  <div class="flex flex-1 items-center justify-between gap-2">
                    <span class="font-bold text-lg dark-mode:text-white truncate w-12 lg:w-auto">{{tweetOfWatchedUser.user.name}}</span>
                    <span class="text-base text-gray-80 dark-mode:text-dark-mode-10 truncate w-12 lg:w-auto">@{{tweetOfWatchedUser.user.username}}</span>
                    <span class="w-1 h-1 bg-gray-80 rounded-full dark-mode:text-dark-mode-10" />
                    <span class="text-base text-gray-80 dark-mode:text-dark-mode-10 truncate w-20 lg:w-auto">{{tweetOfWatchedUser.createdAtFromNow}}</span>
                  </div>
                </div>
              </div>
              <p class="text-lg leading-6 dark-mode:text-white" v-html="formatTweet(tweetOfWatchedUser.text)"/>
              <div class="flex space-x-2" v-if="tweetOfWatchedUser.media">
                <div v-for="media in tweetOfWatchedUser.media" v-bind:key="media.url" class="image-container">
                  <img :src="media.url" v-if="media.type === 'photo'" />
                </div>
              </div>
              <div class="flex items-center gap-4">
                <div class="flex items-center gap-2">
                  <retweet class="dark-mode:hidden" />
                  <dark-retweet class="hidden dark-mode:block" />
                  <span class="dark-mode:text-white">{{tweetOfWatchedUser.retweets}}</span>
                </div>
                <div class="flex items-center gap-2">
                  <heart class="dark-mode:hidden" />
                  <dark-heart class="hidden dark-mode:block" />
                  <span class="dark-mode:text-white">{{tweetOfWatchedUser.likes}}</span>
                </div>
              </div>
              <div class="grid gap-6">
                <div class="grid gap-6">
                  <div class="flex items-center gap-2">
                    <div class="w-8 h-8">
                      <img :src="userProfile.photoURL" :alt="userProfile.username" class="rounded-full w-full h-full object-cover">
                    </div>
                    <span class="font-bold text-xl dark-mode:text-white">{{userProfile.name}}</span>
                    <span class="text-lg text-gray-80 dark-mode:text-dark-mode-10">@{{userProfile.username}}</span>
                  </div>
                </div>
              </div>
              <div class="form-group flex" v-if="!hasUserRepliedToWatchedUser">
                <textarea
                  class="form-field"
                  placeholder="What would you like to say?"
                  cols="10"
                  maxlength="280"
                  v-model="replyToWatchedUsersTweet" />
              </div>
              <div v-else>
                <p class="text-lg leading-6 dark-mode:text-white" v-html="formatTweet(replyToWatchedUsersTweet)"/>
              </div>
              <div class="flex items-center justify-between" v-if="!hasUserRepliedToWatchedUser">
                <quick-reply-emojis @reply-and-like="(emoji) => replyEmojiAndLike(emoji)" />
                <new-button
                  :disabled="replyToWatchedUsersTweet.trim().length === 0"
                  @click="replyToWatchedUser"
                >
                  Reply
                </new-button>
              </div>
            </div>
          </div>
          <new-button size="lg" @click="onboardingStep = 7" class="desktop-only">
            {{ hasUserRepliedToWatchedUser ? 'Next' : 'Skip' }}
          </new-button>
        </div>
      </div>
      <div class="without-scrollbar onboarding-container" v-if="onboardingStep === 7">
        <div class="w-full lg:w-466 grid gap-6 ">
          <h3>Take the pledge</h3>
          <p class="subheading">
            Keep at least 10 tweets in your queue at all times to grow your impressions by x10 in 6
            months
          </p>
          <div class="mb-4">
            <img src="/images/chart-bar.png" alt="" class="mx-auto" />
          </div>
          <div class="form-group">
            <div class="flex">
              <div class="checkbox">
                <input id="follow" type="checkbox" class="hidden" v-model="followHypefuryOnTwitter" :disabled="isSavingUserDetails" />
                <label for="follow" class="dark-mode:text-white text-base md:text-lg">Follow Hypefury on Twitter</label>
              </div>
            </div>
          </div>

          <new-button size="lg" class="desktop-only" @click="viewMyQueue">
            I'll keep 10 tweets in my Queue
          </new-button>
        </div>
      </div>
      <onboarding v-if="onboardingStep === 8" />
    </template>
    <template v-slot:full-content>
      <div class="text-center flex items-center justify-center flex-col">
        <loading-logo />
        <h2 class="text-7xl leading-91 font-bold mt-10 dark-mode:text-white">We’re analyzing your account</h2>
      </div>
    </template>
    <template v-slot:mobile-onboarding>
      <div v-if="analyzing" class="text-center flex items-center justify-center flex-col h-full">
        <loading-logo />
        <h2 class="text-32 leading-10 font-bold my-6 dark-mode:text-white">We’re analyzing your account</h2>
        <p class="text-base text-gray-70 leading-5 dark-mode:text-dark-mode-10">Please confirm your details while we work our magic 🪄</p>
      </div>
      <div v-if="onboardingStep === 0 && !analyzing" class="relative">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">Please confirm your details while we analyze your account.</p>
        </div>
        <div class="grid gap-6 p-6">
          <h3 class="font-bold text-2xl leading-8 dark-mode:text-white">Your details</h3>
          <div class="form-group">
            <label
              for="name"
              class="mb-2 block cursor-pointer text-base font-medium leading-5 text-black dark-mode:text-white"
              >Whats your first name?</label
            >
            <input id="name" type="text" placeholder="Let’s be friends" class="form-field">
          </div>
          <div class="form-group">
            <label for="name" class="block text-base leading-5 font-medium text-black mb-2 cursor-pointer dark-mode:text-white">Timezone</label>
            <select class="form-field">
              <option value="-1" selected disabled>Timezone</option>
              <option value="America/Toronto">America/Toronto</option>
            </select>
          </div>
          <div class="form-group">
            <label for="email" class="block text-base leading-5 font-medium text-black mb-2 cursor-pointer dark-mode:text-white">Email</label>
            <input id="email" type="email" placeholder="Email" class="form-field">
          </div>
          <div class="form-group">
            <div class="flex">
              <div class="checkbox">
                <input id="follow" type="checkbox" class="hidden" checked />
                <label for="follow" class="dark-mode:text-white text-base">Follow Hypefury on Twitter</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="button primary-button block-button" @click.prevent="onboardingStep = 2">This is correct</button>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 1 && !analyzing">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
        </div>
        <div class="grid gap-6 p-6">
          <h3 class="text-2xl font-bold leading-8 dark-mode:text-white">What do you like to tweet about?</h3>
          <div class="flex flex-wrap gap-x-4 gap-y-6">
            <div class="bg-white rounded-full text-base font-medium border border-gray-60 py-2 px-4 cursor-pointer leading-5 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70 dark-mode:text-white">Climate change</div>
            <div class="rounded-full text-base font-medium border-2 py-2 px-4 cursor-pointer leading-5 border-main-color-100 text-main-color-100 bg-main-color-50 dark-mode:text-white dark-mode:bg-dark-mode-70">Energy</div>
            <div class="bg-white rounded-full text-base font-medium border border-gray-60 py-2 px-4 cursor-pointer leading-5 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70 dark-mode:text-white">Green tech</div>
            <div class="bg-white rounded-full text-base font-medium border border-gray-60 py-2 px-4 cursor-pointer leading-5 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70 dark-mode:text-white">Renewables</div>
            <div class="bg-white rounded-full text-base font-medium border border-gray-60 py-2 px-4 cursor-pointer leading-5 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70 dark-mode:text-white">Earth science</div>
            <div class="bg-white rounded-full text-base font-medium border border-gray-60 py-2 px-4 cursor-pointer leading-5 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70 dark-mode:text-white">Science</div>
          </div>
          <div class="form-group">
            <input type="text" placeholder="Don’t see your topic? Try searching" class="form-field">
          </div>
          <div class="form-group">
            <button class="button primary-button block-button" @click.prevent="onboardingStep = 3">Next</button>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 2 && !analyzing">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
        </div>
        <div class="grid gap-6 p-6">
          <div>
            <h3 class="text-2xl font-bold leading-8 mb-4 dark-mode:text-white">Select your first tweet</h3>
            <p class="text-lg leading-6 text-gray-70 dark-mode:text-dark-mode-10">We’ve created something for you to tweet</p>
          </div>
          <div class="form-group">
            <button class="button primary-button block-button" @click.prevent="onboardingStep = 4">Add to queue</button>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 3 && !analyzing">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
        </div>
        <div class="overflow-y-auto w-full without-scrollbar" style="height: 600px">
          <div class="grid gap-6 p-6">
            <div>
              <h3 class="text-2xl font-bold leading-8 mb-4 dark-mode:text-white">Select your first 10 tweets to queue</h3>
              <p class="text-lg leading-6 text-gray-70 dark-mode:text-dark-mode-10">All tweets are original from Hypefury</p>
            </div>
            <div
              v-for="key in 10"
              :key="key"
              class="rounded-2xl p-6 border-2 cursor-pointer"
              :class="key === 2 ? 'bg-main-color-50 border-main-color-100 dark-mode:bg-dark-mode-70' : 'bg-white border-gray-90 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70'">
              <div class="grid gap-6">
                <div class="flex items-center gap-2">
                  <div class="w-8 h-8">
                    <img src="/images/user.png" alt="Richard Shaw" class="rounded-full w-full h-full object-cover">
                  </div>
                  <span class="font-bold text-lg dark-mode:text-white">Richard Shaw</span>
                  <span class="text-base text-gray-80 dark-mode:text-dark-mode-10">@shawrichardo</span>
                </div>
                <p class="text-base leading-none dark-mode:text-white">Climate change is real!</p>
                <div class="flex items-center gap-2">
                  <span class="text-base text-gray-100 dark-mode:text-dark-mode-10">Thursday</span>
                  <span class="text-base text-gray-100 dark-mode:text-dark-mode-10">7:14pm</span>
                </div>
              </div>
            </div>
            <button class="button primary-button block-button" @click.prevent="onboardingStep = 5">0/10 tweets selected</button>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 4 && !analyzing">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
        </div>
        <div class="grid gap-6 p-6">
          <div>
            <h3 class="text-2xl font-bold leading-8 mb-4 dark-mode:text-white">Get 35% more engagement</h3>
            <p class="text-lg leading-6 text-gray-70 dark-mode:text-dark-mode-10">This will auto-retweet any new tweets that become your top 20% most popular to get even more reach</p>
          </div>
          <div class="form-group">
            <div class="rounded-lg px-4 h-60 flex items-center justify-between" :class="isTweetBoosterEnabled ? 'bg-success-50 dark-mode:bg-dark-mode-70' : 'bg-gray-60 dark-mode:bg-dark-mode-70'">
              <span class="text-lg font-medium dark-mode:text-white">Auto re-tweet</span>
              <div class="toggler">
                <input type="checkbox" name="toggle" id="toggle" checked v-model="isTweetBoosterEnabled">
                <label for="toggle"></label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="button primary-button block-button" @click.prevent="onboardingStep = 6">Next</button>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 5 && !analyzing">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
        </div>
        <div class="grid gap-6 p-6">
          <div>
            <h3 class="text-2xl font-bold leading-8 mb-4 dark-mode:text-white">Watched users</h3>
            <p class="text-lg leading-6 text-gray-70 dark-mode:text-dark-mode-10">Engage with these users often to build your network and grow on Twitter.</p>
          </div>
          <div class="grid gap-4">
            <div v-for="i in 3" :key="i" class="flex items-center justify-between p-5 rounded-lg border border-gray-90 text-lg text-black dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70">
              <span class="leading-none dark-mode:text-white">@thepunnyworld</span>
              <div class="cursor-pointer">
                <delete-icon class="dark-mode:hidden" />
                <dark-delete class="hidden dark-mode:block" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="button primary-button block-button" @click="onboardingStep = 7">Next</button>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 6 && !analyzing" class="h-calc overflow-y-auto">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
        </div>
        <div class="grid gap-6 p-6">
          <div>
            <h3 class="text-2xl font-bold leading-8 mb-4 dark-mode:text-white">Send your first reply</h3>
            <p class="text-lg leading-6 text-gray-70 dark-mode:text-dark-mode-10">Try using an emoji to send a quick reply to this person you follow.</p>
          </div>
          <div class="border border-gray-90 rounded-2xl p-6 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70">
            <div class="grid gap-6">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <div class="w-8 h-8">
                    <img :src="tweetOfWatchedUser.user.profile_image_url" :alt="tweetOfWatchedUser.user.name" class="rounded-full w-full h-full object-cover">
                  </div>
                  <span class="font-bold text-lg dark-mode:text-white">tweetOfWatchedUser.user.name</span>
                  <span class="text-base text-gray-80 dark-mode:text-dark-mode-10">@{{tweetOfWatchedUser.user.username}}</span>
                  <span class="w--6 h--6 bg-gray-80 rounded-full dark-mode:text-dark-mode-10" />
                  <span class="text-base text-gray-80 dark-mode:text-dark-mode-10">{{tweetOfWatchedUser.createdAtFromNow}}</span>
                </div>
              </div>
              <p class="text-base leading-6 dark-mode:text-white" v-html="formatTweet(tweetOfWatchedUser.text)"/>
              <div class="flex items-center gap-4">
                <div class="flex items-center gap-2">
                  <retweet class="dark-mode:hidden" />
                  <dark-retweet class="hidden dark-mode:block" />
                  <span class="dark-mode:text-white">{{tweetOfWatchedUser.retweets}}</span>
                </div>
                <div>
                  <heart class="dark-mode:hidden flex items-center gap-2" />
                  <dark-heart class="hidden dark-mode:block" />
                  <span class="dark-mode:text-white">{{tweetOfWatchedUser.likes}}</span>
                </div>
              </div>
              <div class="grid gap-6">
                <div class="grid gap-6">
                  <div class="flex items-center gap-2">
                    <div class="w-8 h-8">
                      <img src="/images/user.png" alt="Richard Shaw" class="rounded-full w-full h-full object-cover">
                    </div>
                    <span class="font-bold text-lg dark-mode:text-white">Richard Shaw</span>
                    <span class="text-base text-gray-80 dark-mode:text-dark-mode-10">@shawrichardo</span>
                  </div>
                  <div>
                    <img src="/images/king.png" alt="king" class="w-6 h-6" />
                  </div>
                </div>
              </div>
              <div class="form-group flex">
                <textarea class="form-field" placeholder="What would you like to say?" cols="10"></textarea>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-3">
                  <div class="cursor-pointer">
                    <img src="/images/tada.png" alt="tada" class="w-6 h-6" />
                  </div>
                  <div class="cursor-pointer">
                    <img src="/images/champagne.png" alt="champagne" class="w-6 h-6" />
                  </div>
                  <div class="cursor-pointer">
                    <img src="/images/like.png" alt="like" class="w-6 h-6" />
                  </div>
                  <div class="cursor-pointer">
                    <img src="/images/smile.png" alt="smile" class="w-6 h-6" />
                  </div>
                  <div class="cursor-pointer">
                    <img src="/images/king.png" alt="king" class="w-6 h-6" />
                  </div>
                </div>
                <button class="button primary-button small-button">Reply</button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="button primary-button block-button" @click="onboardingStep = 8">Next</button>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 7 && !analyzing">
        <div class="bg-main-color-50 p-6 sticky top-0 dark-mode:bg-dark-mode-90">
          <p class="text-lg mb-2 leading-23 dark-mode:text-white">{{ leftPanelSubTitle }}</p>
        </div>
        <div class="grid gap-6 p-6">
          <div>
            <h3 class="text-2xl font-bold leading-8 mb-4 dark-mode:text-white">Posting schedule created</h3>
            <p class="text-lg leading-6 text-gray-70 dark-mode:text-dark-mode-10">We created a default schedule for you to maximize engagement based on your account analysis. You may customize it if you’d like.</p>
          </div>
          <div class="grid gap-4">
            <div class="form-group">
              <button class="button secondary-button block-button">Edit schedule</button>
            </div>
            <div class="form-group">
              <button class="button primary-button block-button" @click="onboardingStep = 9">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="onboardingStep === 8 && !analyzing">
        <div class="bg-success-50 p-6 sticky top-0">
          <p class="text-lg leading-23">Your account has been analyzed and is ready to grow! 🚀</p>
        </div>
        <div class="grid gap-6 p-6">
          <div>
            <h3 class="text-2xl font-bold leading-8 mb-4 dark-mode:text-white">Keep 10+ tweets in your queue at all times</h3>
            <p class="text-lg leading-6 text-gray-70 dark-mode:text-white">To grow your impressions by 991% in 6 months</p>
          </div>
          <div class="mb-4">
            <img src="/images/chart-bar.png" alt="" />
          </div>
          <div class="form-group">
            <button
              class="button primary-button block-button"
              :disabled="!willKeepTenTweets"
              @click="viewMyQueue"
            >
              View my Queue
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:mobile-steps>
      <div class="flex items-center justify-center gap-2">
        <div
          v-for="index in 9"
          :key="index"
          class="h-2 w-2 rounded-lg cursor-pointer"
          :class="onboardingStep === index ? 'bg-main-color-100' : 'bg-gray-60 dark-mode:bg-dark-mode-50'" />
      </div>
    </template>
  </onboarding-layout>
</template>

<script>
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';
  import lodash from 'lodash';
  import moment from 'moment';
  import topLevelDomainsList from 'tld-list';
  import 'moment-timezone';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import FeedAndMentionsMixin from './Mixins/FeedAndMentionsMixin.vue';
  import InspirationCategoriesMixin from '@/views/Mixins/InspirationCategoriesMixin.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin';
  import MobileMixin from './Mixins/MobileMixin';
  import { mapGetters, mapState } from 'vuex';
  import controller from '@/controller';
  import { setTheme } from '@/util/setTheme';
  import OnboardingLayout from './Layout/OnboardingLayout';
  import loadingLogo from './../svgs/loadingLogo';
  import successLogo from './../svgs/successLogo';
  import deleteIcon from './../svgs/delete';
  import darkDelete from './../svgs/darkDelete';
  import heart from './../svgs/heart';
  import darkHeart from './../svgs/darkHeart';
  import retweet from './../svgs/retweet';
  import darkRetweet from './../svgs/darkRetweet';
  import dao from '@/dao';
  import { formatTweet } from '@/util/formatTweet';
  import QuickReplyEmojis from '../components/QuickReplyEmojis.vue';
  import { Thread } from '../models/Thread';
  import { store } from '@/store';
  import hypefuryTrialTweets from '@/data/hypefury-trial-tweets';
  import { v1 as uuidv1 } from 'uuid';
  import TaggingMixin from '@/views/Mixins/TaggingMixin';
  import VirtualList from 'vue-virtual-scroll-list';
  import BestTweetItem from '@/components/BestTweetItem';
  import { initHowuku } from '@/howuku';
  import Onboarding from '@/components/Onboarding';

  export default {
    name: 'setup',
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule', 'postedTweetsLikes']),
      leftPanelTitle() {
        if(this.onboardingStep === 0) {
          return 'Almost there, confirm your details'
        } else {
          return 'We’re analyzing your account'
        };
      },
      leftPanelSubTitle() {
        switch (this.onboardingStep) {
          case 0:
            return 'In the meantime we’re analyzing your account';
          case 1:
            return 'Keep going while we collect your Twitter data!';
          case 2:
            return 'Pick some tweets while we crunch your tweets';
          case 3:
            return 'Halfway there!';
          case 4:
            return '';
          case 5:
            return 'You\'re almost there!';
          case 6:
            return 'You\'re almost there!';
          default:
            return '';
        }
      },
      allTimeZones() {
        return moment.tz.names().map(timezone => timezone.replace(/_/g, ' '));
      },
      isEmailAddressValid() {
        return (this.emailAddress &&
          this.validEmail(this.emailAddress) &&
          this.isEmailTopLevelDomainValid(this.emailAddress));
      },
      extraProps() {
        return {
          tweetsToQueue: this.tweetsToQueue,
          getFormattedTime: this.getFormattedTime,
        }
      },
      categoriesForSetup() {
        const restrictedCategories = [
          'My Best Tweets',
          'Templates',
          'Thread Hooks',
          'Questions',
          'Sh!tposts',
          '#buildinpublic',
          '#tweet100',
          '#nocode',
        ];
        return this.categories.filter((category) => {
          return !restrictedCategories.includes(category.name);
        });
      },
      steps() {
        return this.wasFirstStepDisplayed ? [1, 2, 5, 8] : [2, 5, 8];
      },
      addTweetsToQueueOptions() {
        return {
          label:
            this.userProfile.inspirationTweetsLastIndexedAt && this.usersBestTweets.length < 8
              ? 'Next'
              : this.tweetsToQueue.length < 4
              ? `${this.tweetsToQueue.length}/10 tweets selected`
              : `Queue ${this.tweetsToQueue.length} tweets`,
          disabled:
            this.userProfile.inspirationTweetsLastIndexedAt &&
            this.usersBestTweets.length >= 8 &&
            this.tweetsToQueue.length < 4,
        };
      },
    },
    data() {
      return {
        emailAddress: null,
        emailError: null,
        followHypefuryOnTwitter: true,
        timezoneError: null,
        isEmailAddressFieldValid: true,
        isSavingUserDetails: false,
        providedName: null,
        selectedTimeZone: null,
        theme: null,
        analyzing: true,
        onboardingStep: 0,
        isSavingWatchedUsers: false,
        suggestedWatchedUsers: [{username: 'hypefury'}],
        watchedUsers: [],
        tweetOfWatchedUser: null,
        hasUserRepliedToWatchedUser: false,
        replyToWatchedUsersTweet: '',
        tweetsToQueue: [],
        isTweetBoosterEnabled: true,
        firstTweets: [],
        selectedFirstTweetIndex: 0,
        itemComponent: BestTweetItem,
        areBestTweetsTakingTooLongToLoad: false,
        wasFirstStepDisplayed: false,
      };
    },
    created() {
      initHowuku();
    },
    mounted() {
      if (this.userProfile && this.userProfile.timezone && this.userProfile.email) {
        this.onboardingStep = 7;
      }

      if (moment.tz.guess()) {
        this.selectedTimeZone = moment.tz.guess().replace(/_/g, ' ');
      }
      this.emailAddress = this.userProfile.email;

      this.saveUserDetails();

      store.dispatch('fetchPostedTweetsLikesAndRetweets', true);
      this.populateFirstTweets();
    },
    methods: {
      formatTweet,
      updateTimezone: function (value) {
        const systemTz = value.replace(/ /g, '_');
        this.selectedTimeZone = systemTz;
      },
      saveUserDetails: async function () {
        this.isSavingUserDetails = true;
        const systemTz = this.selectedTimeZone.replace(/ /g, '_');

        let errorFound = false;

        if (!moment.tz.names().includes(systemTz)) {
          this.timezoneError = 'Please select a time zone from the list.';
          errorFound = true;
          this.analyzing = false;
          this.wasFirstStepDisplayed = true;
          this.$nextTick(() => {
            this.$refs.timezone.focus();
          });
        } else {
          this.timezoneError = null;
        }

        if (!this.isEmailAddressValid) {
          errorFound = true;
          this.analyzing = false;
          this.wasFirstStepDisplayed = true;
          this.$nextTick(() => {
            this.$refs.emailAddress.focus();
            this.emailError = 'Invalid email address.';
          });
        } else {
          this.emailError = null;
        }

        if (errorFound) {
          this.isSavingUserDetails = false;
          return;
        }

        await controller.isThereAnotherUserWithTheSameEmailAddress(this.currentUser, this.emailAddress, this.userProfile.uid).then(yesThereIs => {
          if (yesThereIs) {
            this.analyzing = false;
            this.wasFirstStepDisplayed = true;
            this.isEmailAddressFieldValid = false;
            this.$nextTick(() => {
              this.$refs.emailAddress.focus();
              this.emailError = 'Email address already in use.';
            });
            this.isSavingUserDetails = false;
          } else {
            return firebase.firestore().collection('users')
              .doc(this.userProfile.uid)
              .update({
                email: this.emailAddress,
                timezone: systemTz,
                providedName: this.providedName,
                'settings.theme': this.theme,
              })
              .then(() => {
                this.isSavingUserDetails = false;
                this.onboardingStep += 1;
              })
              .catch(() => alert('An error occurred. Please reload the page.'))
          }
        });
        this.analyzing = false;
      },
      saveUserDetailsOnEnter (event) {
        if (event.key === 'Enter') {
          this.saveUserDetails()
        }
      },
      validEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.trim());
      },
      isEmailTopLevelDomainValid(email) {
        const splitEmail = email.split('.');
        const emailTopLevelDomain = splitEmail[splitEmail.length - 1];
        return topLevelDomainsList.includes(emailTopLevelDomain);
      },
      async populateFirstTweets() {
        const firstTweetsPromises = hypefuryTrialTweets.map(async (tweet) => {
          let media = [];
          let mediaURL = null;
          if (tweet.image) {
            const response = await fetch(tweet.image);
            const blob = await response.blob();
            const file = new File([blob], `${uuidv1()}.png`, { type: 'image/png' });
            media = [file];
            mediaURL = URL.createObjectURL(file);
          }
          return {
            text: tweet.status,
            media,
            mediaURL,
          }
        });
        this.firstTweets = await Promise.all(firstTweetsPromises)
      },
      cycleFirstTweetIndex(isForwarding) {
        if (isForwarding) {
          if (this.selectedFirstTweetIndex === hypefuryTrialTweets.length - 1) {
            this.selectedFirstTweetIndex = 0;
          } else {
            this.selectedFirstTweetIndex++;
          }
        } else {
          if (this.selectedFirstTweetIndex === 0) {
            this.selectedFirstTweetIndex = hypefuryTrialTweets.length - 1;
          } else {
            this.selectedFirstTweetIndex--;
          }
        }
      },
      addFirstTweetToQueue(tweet) {
        this.addTweetToQueue(tweet, moment().add(10, 'minutes'));
        this.onboardingStep++;
      },
      async addTweetToQueue(tweet, time) {
        const timeForThread = time || this.schedule.getNextTimeSlot();
        const tweetForThread = {
          status: tweet.text,
          count: 0,
          published: false,
          media: [],
          mediaFile: [],
        };
        if (tweet.media) {
          tweetForThread.fileList = tweet.media;
          this.updateMediaFile(tweetForThread, tweet.media);
        }
        const tweets = tweet.media ? [await this.uploadMedia(tweetForThread)] : [tweetForThread];
        const thread = Thread.newThread({
          time: timeForThread,
          tweets,
          user: this.userProfile.uid,
        });
        return thread.saveToFirestore(
          this.currentUser,
          this.userProfile.uid,
          this.timezone,
          this.$eventStore
        );
      },
      updateTweetsToQueue(tweet) {
        if (this.tweetsToQueue.includes(tweet)) {
          this.tweetsToQueue = lodash.without(this.tweetsToQueue, tweet);
        } else {
          this.tweetsToQueue.push(tweet);
        }
      },
      async addTweetsToQueue() {
        let time = null;
        const promises = this.tweetsToQueue.map((tweet) => {
          time = this.schedule.getNextTimeSlot(time);
          return this.addTweetToQueue(tweet, time);
        });
        Promise.all(promises);
        this.onboardingStep++;
      },
      saveTweetboosterSettings() {
        if (this.isTweetBoosterEnabled && this.postedTweetsLikes) {
          this.fetchAndUpdateTweetBoosterSettings();
        }

        this.onboardingStep = 7;
      },
      addSuggestedWatchedUser(user) {
        this.watchedUsers.push(user.username);
      },
      async saveWatchedUsers() {
        this.isSavingWatchedUsers = true;
        try {
          await this.updateFeedSettings();
        } catch (e) {
          this.isSavingWatchedUsers = false;
          return;
        }
        await this.fetchTweetOfWatchedUsers();
        this.isSavingWatchedUsers = false;
        this.onboardingStep = 6;
      },
      async fetchTweetOfWatchedUsers() {
        this.tweetOfWatchedUser = (await controller.twitter.fetchFeed(
          this.currentUser,
          this.userProfile.uid
        ))[0];
        this.tweetOfWatchedUser.createdAtFromNow = moment(this.tweetOfWatchedUser.createdAt).fromNow();
      },
      replyToWatchedUser() {
        controller.twitter.replyToTweet(
          this.currentUser,
          this.userProfile.uid,
          this.tweetOfWatchedUser.id,
          this.replyToWatchedUsersTweet
        );
        this.hasUserRepliedToWatchedUser = true;
      },
      replyEmojiAndLike(emoji) {
        controller.twitter.likeTweet(this.currentUser, this.userProfile.uid, this.tweetOfWatchedUser.id);
        this.replyToWatchedUsersTweet = emoji;
        this.replyToWatchedUser();
      },
      async viewMyQueue() {
        if (this.followHypefuryOnTwitter) {
          controller.twitter.followHypefuryTwitterAccountAndUpdateRelatedTask(
            this.currentUser,
            this.userProfile.uid
          );
        }
        this.onboardingStep++;
      },
      fetchAndUpdateTweetBoosterSettings() {
        const favoritesThreshold =
          this.postedTweetsLikes.length > 0
            ? Math.max(
                this.postedTweetsLikes[Math.ceil(this.postedTweetsLikes.length * 0.2 - 1)],
                1
              )
            : 2;
        const conditionalRetweetsConditions = {
          delayForRetweet: '6 hours',
          minFavoritesThreshold: favoritesThreshold,
          minRetweetsThreshold: null,
          conditionalRetweetsEnabled: true,
        };
        dao.userProfile.saveGlobalConditionalRetweetsConditionsToFirestore(
          this.userProfile.uid,
          conditionalRetweetsConditions
        );
      },
      nextStep(step) {
        if (step === 1) {
          this.onboardingStep = 4;
        } else {
          this.onboardingStep++;
        }
      },
    },
    components: {
      QuickReplyEmojis,
      OnboardingLayout,
      loadingLogo,
      successLogo,
      deleteIcon,
      darkDelete,
      heart,
      darkHeart,
      retweet,
      darkRetweet,
      VirtualList,
      Onboarding,
    },
    mixins:[
      InspirationCategoriesMixin,
      SwalModalMixin,
      FeedAndMentionsMixin,
      TaggingMixin,
      UploadContainerMixin,
      MobileMixin,
    ],
    watch: {
      onboardingStep(onboardingStep) {
        if (onboardingStep === 1) {
          dao.userProfile.updateOnboardingStep(this.userProfile.uid, 1);
        } else if (onboardingStep === 4) {
          dao.userProfile.updateOnboardingStep(this.userProfile.uid, 2);
        } else if (onboardingStep === 7) {
          dao.userProfile.updateOnboardingStep(this.userProfile.uid, 3);
        } else if (onboardingStep === 8) {
          dao.userProfile.updateOnboardingStep(this.userProfile.uid, 4);
        }

        if (onboardingStep === 3) {
          setTimeout(() => {
            if (!this.areUsersBestTweetsFetched || !this.userProfile.inspirationTweetsLastIndexedAt) {
              this.areBestTweetsTakingTooLongToLoad = true;
            }
          }, 10000);
        }

        const shouldSkipBillingOnboarding = localStorage.getItem('shouldSkipBillingOnboarding');
        if (onboardingStep === 7 && shouldSkipBillingOnboarding) {
          this.$router.push('/queue');
        }
      },
      postedTweetsLikes(val, old) {
        if (!old && val && this.isTweetBoosterEnabled && this.onboardingStep > 4) {
          this.fetchAndUpdateTweetBoosterSettings();
        }
      },
      theme(theme) {
        setTheme(theme);
      },
      'userProfile.tweetsLastFetchedAt': function (val, old) {
        if (!old && val) {
          store.dispatch('fetchPostedTweetsLikesAndRetweets', true);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }
  .loading-tweet {
    animation: blinker 1.28s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .new-ui {
    h3 {
      @apply text-2xl font-bold leading-8;
    }
    .n-label {
      @apply mb-2 block text-base font-medium text-black;
    }

    .subheading {
      @apply text-lg leading-6 text-gray-70;
    }

    .onboarding-container {
      @apply relative flex h-onboarding items-start justify-center overflow-y-auto pb-2 pt-6;
    }

    @media (min-width: 768px) {
      h3 {
        @apply text-32 leading-10;
      }

      .subheading {
        @apply text-base text-gray-70;
      }

      .onboarding-container {
        @apply h-onboarding-height items-center py-0;
      }
    }

    &.mode--dark {
      h3 {
        @apply text-white;
      }

      .n-label {
        @apply text-white;
      }

      .subheading {
        @apply text-white;
      }
    }
  }
</style>
