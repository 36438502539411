<template>
  <div class="loader" v-if="loading">
    <span class="loading loading-lg"><inline-svg src="/img/icons/loading.svg"></inline-svg></span>
  </div>

  <div v-else>
    <div class="mb-6 flex items-center justify-between">
      <h1 class="flex items-center gap-x-2">
        Mentions
        <new-button type="custom" @click="isShortcutsModalVisible = true">
          <inline-svg src="/img/icons/info.svg" class="h-5 w-5 fill-gray" />
        </new-button>
      </h1>
      <new-button @click="clearMentions" type="secondary" v-show="tweets.length > 0">
        Clear timeline
      </new-button>
    </div>
    <div class="space-y-6" v-if="!loading">
      <new-alert
        data-cy="mentions-subscription-alert"
        class="mt-4"
        v-if="userProfile.customerStatus === 'none'"
      >
        <p>
          You are currently on the
          {{ userProfile.customerStatus === 'none' ? 'free' : userProfile.customerStatus }} plan.
          Your mentions timeline is limited.<br />
          <router-link data-cy="mentions-upgrade-link" to="/billing" class="text-main-color-100"
            >Grab a {{ getPlanLabel('standard') }} Hypefury subscription</router-link
          >
          to unlock and see <em>all</em> your mentions.
        </p>
      </new-alert>

      <feed-and-Mentions-shortcuts-modal
        v-if="isShortcutsModalVisible"
        @close="isShortcutsModalVisible = false"
      />

      <div
        class="row justify-content-center"
        :key="midnight"
        :id="midnight"
        v-for="(tweetsInADay, midnight) in tweetsByDate"
      >
        <div sticky-container>
          <new-queue-day-header :userTimezone="userProfile.timezone" :midnight="midnight" />
          <new-feed-item
            :isMention="true"
            :tweet="tweet"
            ref="feed-item-box"
            v-for="tweet in tweetsInADay"
            v-bind:key="tweet.id"
          />
        </div>
        <back-to-top-custom />
      </div>
      <p v-if="!loading && tweets.length === 0" class="text-lg">Your mentions timeline is empty.</p>
    </div>
  </div>
</template>

<script>
  import BackToTopCustom from '@/views/Widgets/BackToTopCustom';
  import controller from '@/controller';
  import NewFeedItem from '@/components/NewFeedItem';
  import FeedAndMentionsMixin from './Mixins/FeedAndMentionsMixin.vue';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import { isBottomOfPage } from '@/util/isBottomOfPage';
  import SwalModalMixin from './Mixins/SwalModalMixin.vue';
  import lodash from 'lodash';
  import NewQueueDayHeader from '@/components/NewQueueDayHeader.vue';
  import FeedAndMentionsShortcutsModal from '@/views/Modals/FeedAndMentionsShortcutsModal.vue';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import { EventBus } from '@/event-bus';

  export default {
    name: 'mentions',
    data() {
      return {
        loading: true,
        tweets: [],
        lastMentionId: null,
        loadingMoreReplies: false,
        isShortcutsModalVisible: false,
      };
    },
    async mounted() {
      if (this.isUserPlanBasicOrAbove(this.userProfile.customerStatus)) {
        await this.fetchMentions();
        this.onScroll();
      }

      this.$eventStore.visitMentionsPage();

      EventBus.$on('hide-tweet', (tweetToHide) => {
        this.tweets = this.tweets.filter((tweet) => tweet.id !== tweetToHide.id);
      });

      this.loading = false;
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      tweetsByDate() {
        return lodash.groupBy(this.tweets, (tweet) => moment(tweet.createdAt).format('YYYY-MM-DD'));
      },
    },
    methods: {
      clearMentions() {
        const latestTweetId = this.tweets[0].id;
        const fb = require('../firebase');
        fb.usersCollection.doc(this.userProfile.uid)
          .update({
            'integrations.twitter.ignoreTweetsSince': latestTweetId,
          });
        this.tweets = [];
      },
      async fetchMentions() {
        try {
          if (!this.lastMentionId) this.loading = true;
          const newTweets = await controller.twitter.mentionsTimeline(this.currentUser, this.userProfile.uid, this.lastMentionId);
          if (!this.lastMentionId) this.loading = false;

          if (this.lastMentionId) {
            // Remove tweet with id === this.lastMentionId
            const updatedTweets = this.tweets.concat(
              newTweets.filter((tweet) => tweet.id !== this.lastMentionId)
            );
            this.tweets = updatedTweets;
          } else {
            this.tweets = newTweets;
          }

          if (this.tweets.length === 100) {
            const lastReply = this.tweets[this.tweets.length - 1];

            if (lastReply) {
              this.lastMentionId = lastReply.id;
            }
          }

          const repliesToIgnore = lodash.get(
            this.userProfile,
            'integrations.twitter.hiddenRepliesIds',
            []
          );
          const repliesToIgnoreMap = new Map(repliesToIgnore.map((replyId) => [replyId, replyId]));
          this.tweets = this.tweets.filter((e) => !repliesToIgnoreMap.get(e.id));
        } catch (error) {
          this.loading = false;
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to retrieve your replies. Please try again.',
            type: 'error',
          });
        }
      },
      async loadMoreReplies() {
        if (this.lastMentionId) {
          this.loadingMoreReplies = true;
          await this.fetchMentions();
          this.loadingMoreReplies = false;
        }
      },
      onScroll() {
        window.onscroll = () => {
          if (isBottomOfPage() && !this.loadingMoreReplies) {
            this.loadMoreReplies();
          }
        };
      },
    },
    beforeDestroy() {
      EventBus.$off('hide-tweet');
    },
    components: {
      BackToTopCustom,
      NewFeedItem,
      NewQueueDayHeader,
      FeedAndMentionsShortcutsModal,
    },
    mixins: [FeedAndMentionsMixin, SwalModalMixin, CustomerStatusMixin],
  };
</script>
