<template>
  <div class="courses-container">
    <iframe
      :src="notionEmbedUrl"
      style="width: 100%; height: 100%; min-height: 500px; border: none"
    />
  </div>
</template>

<script>
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Courses',
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      notionEmbedUrl() {
        const isTrialAllowed = false;
        if (this.isUserPlanPremiumOrAbove(this.userProfile.customerStatus, isTrialAllowed)) {
          // Creator+ (Premium) plan
          return 'https://v2-embednotion.com/12d6392b64398022a29bd4de500749bf';
        } else if (this.isUserSubscribed(this.userProfile.customerStatus, isTrialAllowed)) {
          // Starter (Standard) plan
          return 'https://v2-embednotion.com/12d6392b64398026abf7d1453a2a9032';
        } else {
          // Free plan
          return 'https://v2-embednotion.com/12d6392b643980aab534d0ce83f6d5d3';
        }
      },
    },
    mixins: [CustomerStatusMixin],
  };
</script>

<style scoped>
  .courses-container {
    width: 100%;
    height: 100vh;
    border: none;
    margin: 0 !important;
  }
</style>
