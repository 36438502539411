var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen relative onboarding-font"},[_c('div',{class:[
      _vm.isMobile
        ? 'flex items-center justify-center bg-ds-background p-4'
        : 'absolute right-3 top-3',
    ]},[_c('div',{class:['relative z-50', _vm.isMobile ? 'w-full' : '']},[_c('button',{staticClass:"focus:outline-none flex w-full items-center justify-between gap-2 bg-ds-foreground p-3 md:gap-8",class:{ 'rounded-lg': !_vm.isDropDownOpen, 'rounded-t-lg': _vm.isDropDownOpen },on:{"click":function($event){_vm.isDropDownOpen = !_vm.isDropDownOpen}}},[_c('div',{staticClass:"flex items-center gap-2"},[_c('img',{staticClass:"h-8 w-8 rounded-full md:h-10 md:w-10",attrs:{"src":_vm.userProfile.photoURL}}),_c('div',{staticClass:"flex flex-col gap-1"},[_c('p',{staticClass:"text-sm font-medium text-primary"},[_vm._v(_vm._s(_vm.userProfile.name))]),_c('p',{staticClass:"text-sm font-medium text-ds-text-secondary-tertiary"},[_vm._v(" @"+_vm._s(_vm.userProfile.username)+" ")])])]),_c('inline-svg',{class:[
            'h-4 w-4 transform stroke-current text-ds-button-icon md:h-5 md:w-5',
            _vm.isDropDownOpen && 'rotate-180',
          ],attrs:{"src":"/img/icons/new/chevron.svg"}})],1),_c('transition',{attrs:{"enter-active-class":"transition ease-out duration-100","enter-class":"transform opacity-0 scale-95","enter-to-class":"transform opacity-100 scale-100","leave-active-class":"transition ease-in duration-75","leave-class":"transform opacity-100 scale-100","leave-to-class":"transform opacity-0 scale-95"}},[(_vm.isDropDownOpen)?_c('div',{staticClass:"absolute right-0 w-max min-w-full rounded-b-lg bg-ds-foreground p-3 md:mt-0 md:pt-0"},[_c('new-button',{staticClass:"w-full px-4 py-2 text-left text-sm transition-colors",attrs:{"type":"secondary"},on:{"click":_vm.logout}},[_vm._v(" Log out ")])],1):_vm._e()])],1)]),(!_vm.analyzing)?_c('div',{staticClass:"flex items-start justify-between h-full"},[_c('div',{staticClass:"flex-1 h-full px-6 xl:px-0 hidden md:block",class:_vm.leftBg},[_vm._t("left-content")],2),_c('div',{staticClass:"flex-1 h-full relative px-6 xl:px-0",class:_vm.rightBg},[_vm._t("right-content")],2)]):_c('div',{staticClass:"flex items-center justify-center h-full bg-white dark-mode:bg-dark-mode-80"},[_vm._t("full-content")],2),_c('new-button',{staticClass:"chat-button bottom-14 bg-ds-button-secondary-background",attrs:{"type":"custom"},on:{"click":_vm.toggleCrispChat}},[(!_vm.showCrispChat)?_c('inline-svg',{staticClass:"h-6 w-6 stroke-ds-button-primary-background",attrs:{"src":"/img/icons/new/help.svg"}}):_c('inline-svg',{staticClass:"h-6 w-6",attrs:{"src":"/img/icons/close.svg"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }