<template>
  <div
    class="border relative h-10 w-64 overflow-hidden rounded-full border-ds-outline-secondary bg-ds-background p-1"
  >
    <div class="relative flex h-full w-full items-center justify-between">
      <span
        class="absolute top-0 z-40 h-full w-1/2 rounded-full bg-ds-outline-secondary"
        :class="toggle ? 'left-0' : 'right-0'"
      />
      <div @click="$emit('toggle-interval', true)" class="z-50 flex-1 cursor-pointer">
        <p class="text-center text-sm font-medium text-ds-text-primary" data-cy="billing-monthly">
          Monthly
        </p>
      </div>
      <div @click="$emit('toggle-interval', false)" class="z-50 flex-1 cursor-pointer">
        <p class="text-center text-sm font-medium text-ds-text-primary" data-cy="billing-monthly">
          Yearly
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'toggle-input',
    props: {
      toggle: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
