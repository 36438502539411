import lodash from 'lodash';

function normalizeStringNumber(input) {
  if (!input || typeof input !== 'string') {
    throw new Error('Input must be a string');
  }

  const numeralSystems = {
    arabic: ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'],
    persian: ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'],
    devanagari: ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'],
    bengali: ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'],
    gurmukhi: ['੦', '੧', '੨', '੩', '੪', '੫', '੬', '੭', '੮', '੯'],
    thai: ['๐', '๑', '๒', '๓', '๔', '๕', '๖', '๗', '๘', '๙'],
  };

  const allNumerals = lodash.flatMap(numeralSystems, (numerals) =>
    numerals.map((digit, index) => [digit, index]),
  );

  return lodash.reduce(
    allNumerals,
    (result, [digit, index]) => result.replace(new RegExp(digit, 'g'), index.toString()),
    input,
  );
}

export default normalizeStringNumber;
