<template>
  <div class="space-y-3">
    <h2 class="time-date">
      <span>{{ source.midnight | formatNameOfDay(userProfile.timezone) }}</span>
      <span v-if="index !== 0">
        {{ source.midnight | formatDateOfDay(userProfile.timezone) }}
      </span>
    </h2>
    <ul class="time-list">
      <li v-bind:key="slot.id"
      class="time-slot"
      data-cy="schedule-once-time-slot"
      @click="scheduleAction(slot.time)"
      v-for="slot in availableSourceSlots">
      {{ formatTimeWithUserLocale(slot.time) }}
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin';
  import { Thread } from '../models/Thread';
  import { Retweet } from '../models/Retweet';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { EventBus } from '@/event-bus';

  export default {
    props: {
      index: {
        type: Number
      },
      source: {
        type: Object
      },
      action: {
        type: String
      },
      post: {
        type: Object
      }
    },
    filters: {
      formatNameOfDay: function (time, timezone) {
        const midnightOfToday = moment.tz(timezone).startOf('day');
        const midnightOfTime = moment.tz(time, timezone).startOf('day');

        if (midnightOfToday.diff(midnightOfTime, 'day') === 0) return 'Today';
        if (midnightOfToday.diff(midnightOfTime, 'day') === -1) return 'Tomorrow';
        return midnightOfTime.format('dddd');
      },
      // TODO: Duplicated in ScheduleDraftModal (and maybe somewhere else)
      formatDateOfDay: function (time, timezone) {
        const momentTime = moment.tz(time, timezone);
        const format = momentTime.year() === moment().year() ? 'MMMM DD' : 'LL';
        return momentTime.format(format);
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      availableSourceSlots() {
        return this.source.timeSlots.filter(slot => {
          return slot.isEmpty();
        });
      }
    },
    methods: {
      scheduleAction(slot) {
        if (this.action == 'retweet') {
          this.scheduleRetweet(slot);
        }
      },
      scheduleRetweet(time) {
        const isThread = this.post instanceof Thread;
        this.$parent.$parent.$emit('is-submitting', true);
        const originalTweetInfo = {
          username: this.userProfile.username,
          userDisplayName: this.userProfile.name,
          userProfilePictureURL: this.userProfile.photoURL,
          userTwitterId: this.userProfile.twitterId,
          text: isThread ? this.post.tweets[0].status : this.post.originalTweetInfo.text,
        };
        const retweet = Retweet.newRetweet(isThread ? this.post.tweetIds[0] : this.post.tweetId,
          time,
          this.userProfile,
          originalTweetInfo);
        retweet.saveToFirestore(this.$eventStore)
          .then(() => {
            this.$parent.$parent.$emit('was-submitted', true);
            this.$notify({type: 'success', message: 'Retweet successfully scheduled.' });

            if (this.userProfile.customerStatus === 'none') {
              EventBus.$emit('open-upgrade-plan-modal', {
                planName: 'Starter',
                isUserScheduling: true,
              });
            }
          })
          .catch(error => {
            this.$parent.$emit('was-submitted', false);
            console.error(error);
            this.swalModal({
              title: 'Sorry',
              text: 'Could not schedule this retweet.',
              type: 'error',
            });
          });
      }
    },
    mixins: [TimeFormatterMixin, SwalModalMixin],
  }
</script>
