<script>
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';

  export default {
    methods: {
      logout() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$store.dispatch('unsubscribeFromFirestoreListeners');
            document.cookie = `twitterUserId=hypefury; expires=Thu, 18 Dec 2013 12:00:00 UTC`;

            const shouldSkipBillingOnboarding = localStorage.getItem('shouldSkipBillingOnboarding');

            localStorage.clear();

            // We want to keep the shouldSkipBillingOnboarding value
            if (shouldSkipBillingOnboarding) {
              localStorage.setItem('shouldSkipBillingOnboarding', shouldSkipBillingOnboarding);
            }

            location.reload();
          });
      },
    },
  };
</script>
