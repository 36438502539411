<template>
  <div v-if="isVisible && !isUserYearly && !isStripeCustomer">
    <template>
      <base-modal v-if="!pricesLoaded">
        <Spinner />
      </base-modal>

      <base-modal v-else-if="step === 0" @close="close">
        <div
          class="relative flex flex-col gap-10 bg-ds-background p-10"
          :style="{
            width: isMobile ? '300px' : '529px',
          }"
        >
          <button class="absolute right-3 top-3" @click="close">
            <inline-svg src="/img/icons/close.svg" />
          </button>

          <h1 class="text-center">Basic plan offer</h1>

          <div
            class="border space-y-6 rounded-lg border-ds-outline-primary bg-ds-foreground px-8 py-5"
          >
            <div>
              <span class="flex items-center justify-between">
                <p class="text-base font-semi-medium capitalize">{{ computedPlanName }} Yearly</p>
                <span class="rounded-full bg-black px-3 py-1 text-xl font-bold text-white">
                  Offer ends soon
                </span>
              </span>
              <p class="text-3xl font-bold text-ds-button-primary-background">{{ priceLabel }}</p>
              <p class="text-base font-semi-medium text-secondary">{{ VATPrice }} VAT</p>
            </div>
            <div class="w-full space-y-3 text-base">
              <div class="flex w-full items-center gap-3">
                <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/schedule-icon.svg" />
                <p v-if="scheduleLimit === Infinity">Unlimited Scheduling</p>
                <p v-else>Schedule up to {{ scheduleLimit }} month</p>
              </div>

              <div class="flex w-full items-center gap-3">
                <inline-svg
                  class="h-5 w-5 fill-transparent stroke-blues-50"
                  src="/img/icons/new/avatar-outline.svg"
                />
                <span class="flex items-center gap-1">
                  <p>{{ connectedAccountsLimit }}</p>
                  <inline-svg
                    class="h-5 w-5 stroke-blues-50"
                    src="/img/icons/new/socials/twitter.svg"
                  />
                  <p>accounts</p>
                </span>
              </div>

              <template v-if="['standard', 'basic', 'none'].includes(blackFridayCustomerStatus)">
                <div class="flex w-full gap-3">
                  <inline-svg class="h-5 w-5 text-blues-50" src="/img/icons/new/autoplug.svg" />
                  <p>Autoplugs</p>
                </div>
                <div class="flex w-full gap-3">
                  <inline-svg
                    class="h-5 w-5 fill-transparent stroke-blues-50"
                    src="/img/icons/new/folder-plus-outline.svg"
                  />
                  <p class="flex-1">Viral thread hooks and tweet templates</p>
                </div>
              </template>

              <div class="flex w-full items-center gap-3">
                <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/messages.svg" />
                <span class="flex items-center gap-1">
                  <p>{{ autoDmsLimit }}</p>
                  <inline-svg
                    class="h-5 w-5 stroke-blues-50"
                    src="/img/icons/new/socials/twitter.svg"
                  />
                  <p>Auto-DMs per day</p>
                </span>
              </div>

              <div class="flex w-full gap-3">
                <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/new/siren.svg" />
                <p class="flex-1">
                  Increase engagement by watching up to {{ engagementLimit.users }} users &
                  {{ engagementLimit.keywords }} keywords
                </p>
              </div>

              <div
                v-if="['standard', 'basic', 'none'].includes(blackFridayCustomerStatus)"
                class="flex w-full items-center gap-3"
              >
                <inline-svg
                  class="h-5 w-5 stroke-blues-50"
                  src="/img/icons/new/gumroad-sales-outline.svg"
                />
                <p class="flex-1">Automated Gumroad sales</p>
              </div>

              <div class="flex w-full items-center gap-3">
                <template v-if="['standard', 'basic', 'none'].includes(blackFridayCustomerStatus)">
                  <inline-svg
                    class="h-5 w-5 stroke-blues-50"
                    src="/img/icons/new/chat-support-outline.svg"
                  />
                  <p class="flex-1">Email & Chat support</p>
                </template>
                <template v-if="blackFridayCustomerStatus === 'business'">
                  <inline-svg
                    class="h-5 w-5 stroke-blues-50"
                    src="/img/icons/new/chat-support-outline.svg"
                  />
                  <p class="flex-1">Weekend support</p>
                </template>
              </div>
            </div>
          </div>

          <div class="space-y-3">
            <span class="flex items-center gap-3">
              <new-button @click="close" class="px-6" type="secondary">Go back</new-button>
              <new-button @click="showNextStep(planNameForCheckout)" class="flex-1">
                Take this offer
              </new-button>
            </span>

            <p class="text-center text-sm font-semi-medium text-ds-text-secondary-tertiary">
              The new billing cycle will take effect immediately.
            </p>
          </div>
        </div>
      </base-modal>

      <base-modal v-else-if="step === 1" @close="close">
        <div
          class="relative space-y-6 px-10 py-12"
          :style="{
            width: isMobile ? '300px' : '500px',
          }"
        >
          <button class="absolute right-7 top-9" @click="close">
            <inline-svg src="/img/icons/close.svg" />
          </button>

          <inline-svg class="icon" src="/img/icons/wallet.svg" />
          <h2 v-if="parentUserProfile.subscriptionId">We have all your details!</h2>
          <h2 v-else>This will open the checkout</h2>

          <div class="flex flex-col items-center space-y-6">
            <new-button
              :disabled="isUpgrading"
              @click="
                parentUserProfile.subscriptionId &&
                parentUserProfile.subscriptionStatus !== 'deleted' &&
                parentUserProfile.subscriptionStatus !== 'paused'
                  ? upgradeUserToYearly()
                  : checkout(planName)
              "
              class="w-full py-4"
            >
              Grab
              {{ capitalize(['premium', 'trial'].includes(planName) ? 'creator' : this.planName) }}
              Yearly Deal
            </new-button>
            <!-- <router-link
              class="text-center text-main-color-100 underline"
              :to="`/billing?coupon=${couponCode}`"
              >Go to the billing page</router-link
            > -->
          </div>
        </div>
      </base-modal>
    </template>
  </div>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters, mapState } from 'vuex';
  import { v4 as publicIpv4 } from 'public-ip';
  import controller from '@/controller';
  import lodash from 'lodash';
  import CustomerCountryMixin from '../views/Mixins/CustomerCountryMixin.vue';
  import MobileMixin from '../views/Mixins/MobileMixin.vue';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';
  import * as config from '@/config';
  import Spinner from './Loading/Spinner.vue';

  export default {
    name: 'black-friday-alert',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      Spinner,
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
      ...mapState(['isPaddleReady']),
      couponCode() {
        return lodash.get(this.$route, 'query.offer');
      },
      isStripeCustomer() {
        return lodash.get(this, 'parentUserProfile.isStripeCustomer', false);
      },
      isUserYearly() {
        const subscriptionInterval = lodash.get(this.parentUserProfile, 'subscriptionInterval');
        return subscriptionInterval === 'year';
      },
      shouldUpgradeModalStayHidden() {
        const hiddenOnLoad = localStorage.getItem('black_friday_offer_hidden_on_load');
        return Boolean(hiddenOnLoad);
      },
      blackFridayCustomerStatus() {
        return 'none';
      },
      computedPlanName() {
        return 'basic';
      },
      VATPrice() {
        return this.basicYearly.tax;
      },
      priceLabel() {
        return `${this.basicYearly.newPrice.toFixed(0)}${this.basicYearly.currency}/year`;
      },
      scheduleLimit() {
        switch (this.blackFridayCustomerStatus) {
          case 'none':
            return 1;
          case 'standard':
            return 2;
          case 'premium':
          case 'trial':
            return 3;
          case 'business':
            return Infinity;
          case 'agency':
            return Infinity;
          default:
            return '';
        }
      },
      connectedAccountsLimit() {
        switch (this.blackFridayCustomerStatus) {
          case 'none':
            return 1;
          case 'standard':
            return 1;
          case 'premium':
          case 'trial':
            return 5;
          case 'business':
            return 10;
          case 'agency':
            return 15;
          default:
            return '';
        }
      },
      autoDmsLimit() {
        switch (this.blackFridayCustomerStatus) {
          case 'none':
            return 200;
          case 'standard':
            return 200;
          case 'premium':
          case 'trial':
            return 500;
          case 'business':
            return 650;
          case 'agency':
            return 800;
          default:
            return '';
        }
      },
      engagementLimit() {
        switch (this.blackFridayCustomerStatus) {
          case 'none':
            return {
              users: 30,
              keywords: 5,
            };
          case 'standard':
            return {
              users: 30,
              keywords: 5,
            };
          case 'premium':
          case 'trial':
            return {
              users: 100,
              keywords: 10,
            };
          case 'business':
            return {
              users: Infinity,
              keywords: 20,
            };
          case 'agency':
            return {
              users: Infinity,
              keywords: 50,
            };
          default:
            return {
              users: 30,
              keywords: 5,
            };
        }
      },
      planNameForCheckout() {
        if (['premium', 'trial'].includes(this.blackFridayCustomerStatus)) return 'premium';

        if (this.blackFridayCustomerStatus === 'none') return 'basic';

        return this.blackFridayCustomerStatus;
      },
    },

    data() {
      return {
        customerIp: '',
        step: 0,
        wasClosed: false,
        isUpgrading: false,
        pricesLoaded: false,
        premiumYearly: {},
        standardYearly: {},
        basicYearly: {},
        agencyYearly: {},
        businessYearly: {},
        planName: '',
        discount: null,
        isParityDiscountApplied: false,
        PARITY_DISCOUNT_BY_COUNTRY: {
          AF: 40,
          AL: 40,
          AR: 50,
          DZ: 50,
          AO: 50,
          AM: 50,
          AZ: 50,
          BH: 10,
          BD: 50,
          BR: 40,
          BY: 40,
          BZ: 20,
          BJ: 20,
          BT: 30,
          BO: 50,
          BA: 50,
          BW: 50,
          BN: 20,
          BG: 40,
          BF: 50,
          BI: 50,
          KH: 40,
          CM: 40,
          CN: 40,
          CV: 40,
          CF: 50,
          CZ: 20,
          TD: 50,
          CL: 40,
          CO: 50,
          KM: 30,
          CD: 50,
          CG: 50,
          CR: 30,
          HR: 20,
          HU: 30,
          CY: 20,
          DJ: 50,
          DM: 40,
          DO: 50,
          TL: 50,
          EC: 40,
          EG: 50,
          SV: 40,
          GQ: 40,
          ER: 50,
          EE: 20,
          ET: 50,
          FJ: 40,
          GA: 50,
          GM: 50,
          GE: 40,
          GH: 50,
          GR: 20,
          GT: 30,
          GN: 50,
          GW: 50,
          HT: 40,
          HN: 40,
          ID: 40,
          IN: 40,
          IQ: 50,
          CI: 40,
          JM: 30,
          JO: 30,
          KZ: 50,
          KE: 50,
          KW: 30,
          KG: 50,
          LA: 40,
          LV: 30,
          LS: 50,
          LR: 50,
          LT: 20,
          MX: 30,
          MK: 30,
          MG: 40,
          MW: 40,
          MY: 40,
          MV: 20,
          ML: 40,
          MR: 40,
          MU: 30,
          MD: 50,
          MN: 50,
          MA: 40,
          MZ: 50,
          NA: 50,
          NP: 50,
          NE: 50,
          NG: 50,
          PK: 50,
          PL: 30,
          PS: 40,
          PA: 30,
          PY: 50,
          PE: 50,
          PH: 40,
          PT: 20,
          PR: 20,
          RO: 40,
          RW: 50,
          SA: 30,
          SN: 40,
          RS: 30,
          SL: 50,
          SI: 20,
          SS: 50,
          LK: 50,
          SR: 50,
          SZ: 50,
          TJ: 50,
          TZ: 50,
          TG: 40,
          TH: 30,
          TN: 50,
          TM: 30,
          TR: 40,
          UG: 50,
          UY: 40,
          UZ: 50,
          VU: 50,
          VN: 50,
          ZM: 50,
          ZW: 50,
          RU: 30,
          ZA: 20,
          UA: 40,
        },
      };
    },
    methods: {
      capitalize: lodash.capitalize,
      openPaddle() {},
      close() {
        this.step = 0;
        this.$emit('close');
      },
      showNextStep(planName) {
        this.step += 1;
        this.planName = planName;
      },
      async upgradeUserToYearly() {
        this.isUpgrading = true;
        let planToUse;
        let blackFridayDiscount;

        if (this.planName === 'basic') {
          planToUse = this.basicYearly;
        } else if (this.planName === 'standard') {
          planToUse = this.standardYearly;
          blackFridayDiscount = 37;
        } else if (['premium', 'business'].includes(this.planName)) {
          planToUse = this.planName === 'premium' ? this.premiumYearly : this.businessYearly;
          blackFridayDiscount = 36;
        } else if (this.planName === 'agency') {
          planToUse = this.agencyYearly;
          blackFridayDiscount = 33;
        }

        const newPrice = (blackFridayDiscount / 100) * planToUse.net;

        try {
          let newPriceInfo = {};
          if (this.planName === 'standard' && this.isParityDiscountApplied) {
            newPriceInfo = {
              recurring_price: planToUse.net - newPrice,
              quantity: 1,
              currency: planToUse.currency,
            };

            await controller.upgradeUserToYearly(
              this.currentUser,
              newPriceInfo,
              this.couponCode,
              lodash.capitalize(this.planName),
              true,
            );
          } else {
            await controller.upgradeUserToYearlyBlackFriday(
              this.currentUser,
              lodash.capitalize(this.planName),
              this.customerIp,
            );
          }

          this.$notify({
            type: 'success',
            message: `You have successfully upgraded to ${lodash.capitalize(
              this.planName,
            )} Yearly plan!`,
          });
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while trying to upgrade you to the Yearly plan.`,
            type: 'warning',
          });
        } finally {
          this.isUpgrading = false;
          this.close();
        }
      },
      getProductId(plan) {
        return config.paddleInfo.plans[`${plan}Yearly`];
      },
      async checkout(plan) {
        const camelCasePlan = plan
          .toLowerCase()
          .split(' ')
          .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
          .join('');
        const product = this.getProductId(camelCasePlan);

        let coupon;
        if (this.blackFridayCustomerStatus === 'trial') {
          coupon = 'BLACKFRIDAY2024PR';
        } else if (this.blackFridayCustomerStatus === 'premium') {
          coupon = 'BLACKFRIDAY2024PR';
        } else if (this.blackFridayCustomerStatus === 'business') {
          coupon = 'BLACKFRIDAY2024BU';
        } else if (this.blackFridayCustomerStatus === 'agency') {
          coupon = 'BLACKFRIDAY2024AG';
        } else if (this.blackFridayCustomerStatus === 'standard') {
          coupon = 'BLACKFRIDAY2024ST';
        }

        const theme = localStorage.getItem('theme');
        window.Paddle.Checkout.open({
          country: this.countryCode,
          email: this.parentUserProfile.email,
          product,
          passthrough: JSON.stringify({ userId: this.parentUserProfile.uid }),
          displayModeTheme: !['dark', 'light'].includes(theme) ? 'light' : theme,
          ...(coupon ? { coupon } : {}),
        });
      },
      loadPaddle() {
        if (!window.Paddle) return;
        if (!config.isEnvProd) {
          window.Paddle.Environment.set('sandbox');
        }

        window.Paddle.Setup({
          vendor: config.paddleInfo.vendorId,
          eventCallback: async (data) => {
            const eventData = data.eventData;
            if (data.event === 'Checkout.PaymentComplete') {
              // First promoter integration
              window.fpr &&
                window.fpr('referral', {
                  email: eventData.user.email,
                  uid: eventData.user.id,
                });
            }

            if (data.event === 'Checkout.Coupon.Applied') {
              const coupon = eventData.coupon.coupon_code;
              const intervalCoupon = this.intervalCoupons[coupon];
              if (intervalCoupon) {
                const months = intervalCoupon.durationInMonths;
                const monthsText = months === 1 ? 'month' : 'months';
                this.$notify({
                  type: 'success',
                  message: `Your coupon has been applied successfully and it will be valid for ${months} ${monthsText}!`,
                });
              }
            }

            if (data.event === 'Checkout.Complete') {
              window.dataLayer = window.dataLayer || [];
              this.close();

              // eslint-disable-next-line no-inner-declarations, prettier/prettier, no-undef
              function gtag(){dataLayer.push(arguments)};
              gtag('event', 'purchase', {
                transaction_id: data.eventData.checkout.id,
                value: data.eventData.checkout.prices.customer.total,
                currency: data.eventData.checkout.prices.customer.currency,
              });
            }
          },
        });
      },
    },
    async mounted() {
      if (localStorage.getItem('blackFridayOffer') === 'basic' || this.couponCode === 'basic') {
        await this.getCustomerCountry();
        this.customerIp = await publicIpv4();
        this.discount = this.PARITY_DISCOUNT_BY_COUNTRY[this.countryCode];
        const planPrices = await controller.getPlansPrices(this.currentUser, this.customerIp);
        const yearlyPlans = planPrices.filter((plan) => plan.subscription.interval === 'year');
        const monthlyPlans = planPrices.filter((plan) => plan.subscription.interval === 'month');

        const standardYearly = yearlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('standard'),
        );
        const standardMonthly = monthlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('standard'),
        );

        this.standardYearly.currency = standardYearly.currency;
        this.standardYearly.gross = standardMonthly.price.gross * 12;
        this.standardYearly.net = standardMonthly.price.net * 12;
        this.$set(this.standardYearly, 'tax', standardYearly.price.tax);
        const blackFridayPercentage = 37;
        if (this.discount && this.discount >= blackFridayPercentage) {
          this.standardYearly.discount = this.discount;
          this.isParityDiscountApplied = true;
        } else {
          this.standardYearly.discount = blackFridayPercentage;
        }

        this.standardYearly.newPrice =
          standardMonthly.price.net * 12 -
          (standardMonthly.price.net * 12 * this.standardYearly.discount) / 100;

        const premiumYearly = yearlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('premium'),
        );
        const premiumMonthly = monthlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('premium'),
        );

        this.premiumYearly.currency = premiumYearly.currency;
        this.premiumYearly.gross = premiumMonthly.price.gross * 12;
        this.premiumYearly.net = premiumMonthly.price.net * 12;
        this.$set(this.premiumYearly, 'tax', premiumYearly.price.tax);
        this.premiumYearly.newPrice =
          premiumMonthly.price.net * 12 - (premiumMonthly.price.net * 12 * 36) / 100;

        const basicYearly = yearlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('basic'),
        );
        this.basicYearly.currency = basicYearly.currency;
        this.basicYearly.gross = basicYearly.price.gross;
        this.basicYearly.net = basicYearly.price.net;
        this.$set(this.basicYearly, 'tax', basicYearly.price.tax);
        this.basicYearly.newPrice = basicYearly.price.net;

        const agencyYearly = yearlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('agency'),
        );
        const agencyMonthly = monthlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('agency'),
        );
        this.agencyYearly.currency = agencyYearly.currency;
        this.agencyYearly.gross = agencyMonthly.price.gross * 12;
        this.agencyYearly.net = agencyMonthly.price.net * 12;
        this.$set(this.agencyYearly, 'tax', agencyYearly.price.tax);
        this.agencyYearly.newPrice =
          agencyMonthly.price.net * 12 - (agencyMonthly.price.net * 12 * 33) / 100;

        const businessYearly = yearlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('business'),
        );
        const businessMonthly = monthlyPlans.find((plan) =>
          plan.product_title.toLowerCase().includes('business'),
        );
        this.businessYearly.currency = businessYearly.currency;
        this.businessYearly.gross = businessMonthly.price.gross * 12;
        this.businessYearly.net = businessMonthly.price.net * 12;
        this.$set(this.businessYearly, 'tax', businessYearly.price.tax);
        this.businessYearly.newPrice =
          businessMonthly.price.net * 12 - (businessMonthly.price.net * 12 * 36) / 100;

        this.pricesLoaded = true;
      }
    },
    mixins: [SwalModalMixin, CustomerCountryMixin, MobileMixin, CustomerStatusMixin],
    watch: {
      isPaddleReady: {
        immediate: true,
        handler(val) {
          if (val) {
            this.loadPaddle();
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mode--light {
    .bulletpoint-bg {
      background-color: black;
    }

    .icon {
      stroke: #0078fb;
    }
  }

  .mode--dark {
    .bulletpoint-bg {
      background-color: white;
    }

    .icon {
      stroke: white;
    }
  }
</style>
