<template>
  <div class="grid gap-8">
    <div class="app-settings-section border space-y-4 border-gray-90 p-6 rounded-2xl">
      <h3>External Apps</h3>
      <p class="text-base">Generate API keys to connect with other apps</p>
      <ul class="list-none mx-0 space-y-2">
        <li
          v-for="apiKey in userProfile.externalAppsAPIKeys"
          :key="apiKey"
          class="rounded-md"
        >
          <div class="flex space-x-2">
            <base-input
              :value="apiKey"
              appendIcon="/img/icons/copy.svg"
              @append-icon-click="
                $copyText(apiKey);
                $notify({ type: 'success', message: 'Copied!' });
              "
              disabled
            />
            <button class="button small-button secondary-button" @click="deleteAPIKey(apiKey)">
              Delete
            </button>
          </div>
          <label v-if="isKeyInUse(apiKey)">
            Connected with {{ getAppName(apiKey) }}
          </label>
        </li>
      </ul>
      <new-button @click="generateAPIKey" :disabled="isGeneratingAPIKey || !canGenerateMoreAPIKeys">
        Generate new key
      </new-button>
    </div>
    <!---
    <div class="app-settings-section border border-gray-90 p-6 rounded-2xl">
      <h3 class="text-2xl font-bold mb-6 leading-none">Open AI API</h3>
      <p class="mb-6 text-lg">
        Enter your own OpenAI API key to use our AI feature without monthly limitations. Please
        refer to <a href="https://openai.com/pricing">OpenAI website</a> for pricing.
      </p>
      <base-input v-model="openAIKey" class="max-w-md" />
      <div class="mt-6">
        <button
          class="button small-button primary-button"
          @click="saveOpenAIKey"
          :disabled="!canSaveOpenAIKey"
        >
          Save
        </button>
      </div>
    </div>
    --->
    <div class="app-settings-section border space-y-4 rounded-2xl border-gray-90 p-6">
      <h3>Extensions</h3>
      <div class="mb-2 flex items-center">
        <inline-svg src="/img/blackmagic.svg" />
        <p class="ml-4 text-lg">Black Magic Sidebar</p>
      </div>
      <p class="text-base">
        Improve your Twitter Growth while staying in Twitter with the Black Magic Chrome Extension.
        Now get Hypefury Inspiration directly in Black Magic.
      </p>
      <new-button @click="openBlackMagicWebsite()"> Get this integration </new-button>
    </div>
  </div>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import controller from '@/controller';
  import dao from '@/dao';
  import lodash from 'lodash';
  import { EventBus } from '@/event-bus';

  export default {
    name: 'external-apps',
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      canGenerateMoreAPIKeys() {
        return this.userProfile.externalAppsAPIKeys.length < 5;
      },
      canSaveOpenAIKey() {
        const savedOpenAIKey = lodash.get(this.userProfile, 'settings.openAIKey', null);
        if (this.isSavingOpenAIKey) {
          return false;
        } else if (savedOpenAIKey && savedOpenAIKey === this.openAIKey) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted() {
      this.openAIKey = lodash.get(this.userProfile, 'settings.openAIKey', '');
    },
    data() {
      return {
        isGeneratingAPIKey: false,
        isSavingOpenAIKey: false,
        openAIKey: '',
      };
    },
    methods: {
      async saveOpenAIKey() {
        if (this.openAIKey.length < 20) {
          this.$notify({
            type: 'warning',
            message: 'Invalid OpenAI key',
          });
        } else {
          try {
            this.isSavingOpenAIKey = true;
            await dao.userProfile.saveOpenAIKey(this.userProfile.uid, this.openAIKey.trim());
            this.$notify({
              type: 'success',
              message: 'OpenAI key saved !',
            });
          } catch (err) {
            this.$notify({
              type: 'warning',
              message: 'Error saving OpenAI key',
            });
          } finally {
            this.isSavingOpenAIKey = false;
          }
        }
      },
      openBlackMagicWebsite() {
        window.open('https://blackmagic.so', '_blank').focus();
      },
      deleteAPIKey(apiKey) {
        if (this.isKeyInUse(apiKey)) {
          return this.swalModal({
            title: 'Are you sure?',
            html: `Deleting this API key will revoke access from ${this.getAppName(
              apiKey
            )}.<br>Are you sure you want to delete it?`,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            preConfirm: () => {
              return dao.userProfile.deleteExternalAppAPIKey(this.userProfile.uid, apiKey);
            },
          });
        }
        return dao.userProfile.deleteExternalAppAPIKey(this.userProfile.uid, apiKey);
      },
      async generateAPIKey() {
        if (this.userProfile.customerStatus === 'none') {
          EventBus.$emit('open-upgrade-plan-modal', {
            planName: 'Starter',
            featureName: 'External API',
            featureAdvantage: 'use external API',
            isUserScheduling: false,
          });

          return;
        }

        try {
          this.isGeneratingAPIKey = true;
          await controller.generateAPIKey(this.currentUser, this.userProfile.uid);
        } catch (error) {
          console.log(error);
        } finally {
          this.isGeneratingAPIKey = false;
        }
      },
      getAppName(apiKey) {
        return Object.values(this.userProfile.externalApps).find((app) => lodash.get(app, 'apiKeys', []).includes(apiKey))
          .name;
      },
      isKeyInUse(apiKey) {
        return Object.values(this.userProfile.externalApps).some((app) => lodash.get(app, 'apiKeys', []).includes(apiKey));
      },
    },
    mixins: [SwalModalMixin],
  };
</script>
