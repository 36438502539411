<template>
  <base-modal @close="$emit('close')">
    <div class="relative flex flex-col gap-6 rounded-2xl bg-ds-foreground p-10">
      <inline-svg
        @click="$emit('close')"
        src="/img/icons/close.svg"
        class="absolute right-5 top-5 h-4 w-4 cursor-pointer"
      />
      <div class="flex max-w-sm flex-col gap-3">
        <span class="flex items-center gap-3">
          <p class="text-2xl font-bold">Upgrade to {{ planName }}</p>
          <inline-svg src="/img/icons/new/premium-crown.svg" class="h-5 w-5" />
        </span>
        <p
          v-if="isUserScheduling"
          class="text-base font-semi-medium text-ds-text-secondary-tertiary"
        >
          Your post was scheduled but it won't be posted unless you upgrade to
          {{ planName }} {{ addition }}
        </p>
        <p v-else class="text-base font-semi-medium text-ds-text-secondary-tertiary">
          {{ featureName }} is only available for users with the {{ planName }} plan or higher.
          Upgrade now to {{ featureAdvantage }}
        </p>
      </div>
      <div class="flex items-center justify-end gap-3">
        <new-button @click="$emit('close')" type="secondary">Cancel</new-button>
        <new-button @click="navigateToBilling">See all plans</new-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import UpgradeToNextPlanMixin from '../Mixins/UpgradeToNextPlanMixin.vue';

  export default {
    name: 'upgrade-plan-modal',
    props: {
      planName: {
        type: String,
      },
      featureName: {
        type: String,
      },
      featureAdvantage: {
        type: String,
      },
      addition: {
        type: String,
        default: '',
      },
      isUserScheduling: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {};
    },
    mixins: [UpgradeToNextPlanMixin],
    methods: {
      navigateToBilling() {
        this.$router.push('/billing');
        this.$emit('close');
      },
    },
  };
</script>
