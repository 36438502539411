var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"relative inline-block h-full"},[_c('div',{staticClass:"h-full"},[(!_vm.extended)?_c('new-button',{class:[_vm.buttonClasses, _vm.disabled && 'pointer-events-none'],attrs:{"type":_vm.buttonType,"size":_vm.buttonSize,"icon":_vm.icon,"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();_vm.isOpen = !_vm.isOpen}}},[_vm._t("button",function(){return [_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(!_vm.isOpen ? 'Open' : 'Closed'))]),_c('inline-svg',{class:['h-10 w-10', _vm.isOpen ? 'fill-main-color-100' : 'fill-current'],attrs:{"src":"/img/icons/options-icon.svg"}})]},{"isOpen":_vm.isOpen})],2):_c('div',{staticClass:"flex"},[_vm._t("extended-button"),_c('new-button',{attrs:{"icon":_vm.icon,"size":_vm.buttonSize,"type":_vm.extendedButtonType,"data-cy":"compose-schedule-open"},on:{"click":function($event){$event.preventDefault();_vm.isOpen = !_vm.isOpen}}},[_c('inline-svg',{staticClass:"h-4 w-4 transform stroke-ds-button-primary-label",class:{ 'h-4 w-4 -rotate-90': _vm.isDesktop, 'h-6 w-6': !_vm.isDesktop },attrs:{"src":_vm.isDesktop ? '/img/icons/new/modal-collapse.svg' : '/img/icons/arrow-right-icon.svg'}})],1)],2)],1),_c('portal',{attrs:{"to":"modal"}},[_c('transition',{attrs:{"enter-active-class":"transition duration-100 ease-out","enter-class":"transform opacity-0 scale-95","enter-to-class":"transform opacity-100 scale-100","leave-active-class":"transition ease-in duration-75","leave-class":"transform opacity-100 scale-100","leave-to-class":"transform opacity-0 scale-95"},on:{"enter":_vm.onEnter,"leave":_vm.onLeave}},[(_vm.isOpen)?_c('div',{ref:"panel",staticClass:"focus:outline-none z-300 mt-2 w-full rounded-xl bg-ds-foreground shadow-tooltip sm:w-max sm:max-w-md",class:{
          'origin-top-right': _vm.placement === 'right',
          'origin-top-left': _vm.placement === 'left',
          'origin-top': _vm.placement === 'center',
          'p-4': _vm.padded,
          absolute: !_vm.fixed,
          'rounded-b-none rounded-t-2xl sm:rounded-xl ': _vm.isStickyOnMobile,
        },attrs:{"role":"menu","aria-labelledby":"menu","tabindex":"-1"}},[_vm._t("default",null,{"isOpen":_vm.isOpen})],2):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }