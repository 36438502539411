export function initCrisp(crispWebsiteID, store) {
  const loggedInUser = store.getters.getUserProfile;
  const isUserLoggedIn = loggedInUser && loggedInUser.uid;

  if (!isUserLoggedIn) {
    return;
  } else {
    window.CRISP_TOKEN_ID = loggedInUser.uid;
    window.CRISP_WEBSITE_ID = crispWebsiteID;
    loadCrisp();

    const provider = loggedInUser.isStripeCustomer ? 'stripe' : 'paddle';

    window.$crisp = [
      ['safe', true],
      ['set', 'user:nickname', [loggedInUser.name]],
      ['set', 'user:email', [loggedInUser.email]],
      [
        'set',
        'session:data',
        [
          [
            ['created_at', loggedInUser.created_at.toDate().getTime()],
            ['currently_scheduled_posts', loggedInUser.currentlyScheduledPostsCount],
            ['followers_count', loggedInUser.followersCount],
            ['following_count', loggedInUser.followingCount],
            [`${provider}_customer_id`, loggedInUser.subscriptionCustomerId],
            [`${provider}_tier_name`, loggedInUser.subscriptionTierName],
            [`${provider}_status`, loggedInUser.subscriptionStatus],
            ['total_scheduled_retweets', loggedInUser.totalScheduledRetweets],
            ['total_scheduled_tweets', loggedInUser.totalScheduledPosts],
            ['twitter_username', loggedInUser.username],
            ['user_id', loggedInUser.uid],
          ]
        ]
      ]
    ];

    if (loggedInUser.queueLastUpdatedAt) {
      window.$crisp.push([
        'set',
        'session:data',
        [[['queue_last_updated_at', loggedInUser.queueLastUpdatedAt.toDate().getTime()]]],
      ]);
    }
  }
}

function loadCrisp() {
  (function () {
    const d = document;
    const s = d.createElement('script');
    s.src = '//client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
}
