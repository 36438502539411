import 'firebase/compat/auth';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { isInfluencer, isWriter } from '@/util/getGrowthProgramRole';
import { switchToProfile } from '@/util/switchToProfile';
import routes from './routes';
import lodash from 'lodash';
import { removeQueryParamFromURL } from '../util/removeQueryParamFromURL';
const fb = require('../firebase');
import { wasUserCreatedBeforePlansChanges } from '../../functions/src/util/wasUserCreatedBeforePlansChanges';

Vue.use(VueRouter);

const CS_AGENTS = [
  '267420814',
  '40318534',
  '1175510279309942784',
  '1146150479308742656',
  '790996715281129472',
  '1292863147502600192',
  '1171488848230006787',
  '1086140930086920192',
  '1205601901',
  '1235112925972447234',
  '1245547485298339841',
  '1611352735156244482',
  '1668869807737806850',
  '940462221267750912',
  '1847048221396553729',
];

function beforeEach(requiresCS, requiresAdmin, requiresAuth, requiresInfluencer, requiresWriter, requiresBetaFeatureSetting, requiresNonChildUser, requiresOwnerShip, requiresGhostwriter, currentUser, to, from, store) {
  const isHypefurySetupComplete =
    store.getters.getUserProfile &&
    store.getters.getUserProfile.timezone &&
    store.getters.getUserProfile.email;

  const isChildUser = () => {
    const userParentsCustomerStatus = lodash.get(store, 'getters.getUserProfile.parentsCustomerStatus', {});
    return lodash.size(userParentsCustomerStatus) > 0;
  }

  const isGhostwriter = lodash.get(store, 'getters.getUserProfile.isGhostwriter', false);

  if (lodash.get(to, 'query.offer') === 'basic') {
    localStorage.setItem('blackFridayOffer', 'basic');
  }

  if (lodash.get(to, 'query.managerInvitationToken')) {
    localStorage.setItem('shouldSkipBillingOnboarding', true);
  }

  if (to.fullPath === '/create' && to.redirectedFrom) {
    const userProfile = store.getters.getUserProfile;
    const defaultHomepage = lodash.get(userProfile, 'settings.defaultHomepage', 'queue');

    if (userProfile && defaultHomepage) {
      return `/${defaultHomepage}`;
    }

    return to.fullPath;
  }

  const shouldSkipBillingOnboarding = localStorage.getItem('shouldSkipBillingOnboarding');
  const shouldRedirectToQueue =
    isHypefurySetupComplete &&
    (lodash.get(store, 'getters.getUserProfile.customerStatus') !== 'none' ||
      wasUserCreatedBeforePlansChanges(lodash.get(store, 'getters.getUserProfile.created_at')) ||
      lodash.get(store.getters, 'getUserProfile.subscriptionId') ||
      shouldSkipBillingOnboarding);

  if (requiresAuth && to.path !== '/setup' && currentUser && !shouldRedirectToQueue) {
    return '/setup';
  }

  if (requiresAuth && to.path === '/setup' && currentUser && shouldRedirectToQueue) {
    return '/';
  }

  if (
    requiresAuth &&
    currentUser &&
    ['auto_dm_campaigns', 'auto_dm_users', 'drafts', 'twitter-archive-uploader'].includes(
      to.meta.id
    )
  ) {
    return '/';
  }

  if (
    requiresAuth &&
    to.path !== '/billing' &&
    currentUser &&
    isHypefurySetupComplete &&
    store.getters.getUserProfile.source &&
    store.getters.getUserProfile.customerStatus === 'none'
  ) {
    return '/billing';
  }
  if (requiresAdmin && currentUser && !store.getters.getUserProfile.isAdmin) {
    return '/';
  }

  if (requiresInfluencer && currentUser && !isInfluencer(store.getters.getUserProfile)) {
    return '/';
  }
  if (
    requiresBetaFeatureSetting &&
    (!store.getters.getUserProfile.settings.betaFeatures ||
      (store.getters.getUserProfile.settings.betaFeatures[to.meta.betaFeature] &&
        !store.getters.getUserProfile.settings.betaFeatures[to.meta.betaFeature].enabled))
  ) {
    return from.path;
  }
  if (requiresNonChildUser && isChildUser()) {
    return '/'
  }
  if (requiresOwnerShip && to.path === '/billing' && store.getters.getIsUserManaging) {
    return '/';
  }
  if (requiresGhostwriter && isGhostwriter !== true) {
    return '/';
  }
  if (requiresWriter && currentUser && !isWriter(store.getters.getUserProfile)) {
    return '/';
  }
  if (requiresCS && currentUser && !CS_AGENTS.includes(store.getters.getUserProfile.twitterId)) {
    return '/';
  }
  if (currentUser && to.path === '/login') {
    return '/';
  }
  if (requiresAuth && !currentUser) {
    localStorage.setItem('nextURL', to.fullPath);
    return '/login';
  }
  if (to.query.account && store.getters.getUserProfile && to.query.account !== store.getters.getUserProfile.uid) {
    switchToProfile(to.query.account);
  }

  return undefined;
}

function router(store) {
  const router = new VueRouter({
    mode: 'history',
    routes: routes(store),
    linkActiveClass: 'active',
    scrollBehavior: (to, from ,savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return { selector: to.hash };
      }
      return { x: 0, y: 0 };
    }
  });

  router.beforeEach((to, from, next) => {
    const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin);
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
    const requiresInfluencer = to.matched.some(x => x.meta.requiresInfluencer);
    const requiresWriter = to.matched.some(x => x.meta.requiresWriter);
    const requiresBetaFeatureSetting = to.matched.some(x => x.meta.requiresBetaFeatureSetting);
    const requiresNonChildUser = to.matched.some(x => x.meta.requiresNonChildUser);
    const requiresOwnerShip = to.matched.some(x => x.meta.requiresOwnerShip);
    const requiresGhostwriter = to.matched.some(x => x.meta.requiresGhostwriter);
    const requiresCS = to.matched.some(x => x.meta.requiresCS);
    const currentUser = fb.auth.currentUser;
    return next(beforeEach(requiresCS, requiresAdmin, requiresAuth, requiresInfluencer, requiresWriter, requiresBetaFeatureSetting, requiresNonChildUser, requiresOwnerShip, requiresGhostwriter, currentUser, to, from, store));
  });

  router.afterEach((to) => {
    try {
      ga('set', 'page', to.path);
      ga('send', 'pageview');
    } catch {
      //
    }

    if (to.query.account === lodash.get(store, 'getters.getUserProfile.uid')) {
      removeQueryParamFromURL(router, to, 'account');
    }
  });

  return router;
}

export { router, beforeEach };
