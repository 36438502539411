<template>
  <div class="h-screen relative onboarding-font">
    <div
      :class="[
        isMobile
          ? 'flex items-center justify-center bg-ds-background p-4'
          : 'absolute right-3 top-3',
      ]"
    >
      <div :class="['relative z-50', isMobile ? 'w-full' : '']">
        <button
          @click="isDropDownOpen = !isDropDownOpen"
          class="focus:outline-none flex w-full items-center justify-between gap-2 bg-ds-foreground p-3 md:gap-8"
          :class="{ 'rounded-lg': !isDropDownOpen, 'rounded-t-lg': isDropDownOpen }"
        >
          <div class="flex items-center gap-2">
            <img class="h-8 w-8 rounded-full md:h-10 md:w-10" :src="userProfile.photoURL" />
            <div class="flex flex-col gap-1">
              <p class="text-sm font-medium text-primary">{{ userProfile.name }}</p>
              <p class="text-sm font-medium text-ds-text-secondary-tertiary">
                @{{ userProfile.username }}
              </p>
            </div>
          </div>
          <inline-svg
            src="/img/icons/new/chevron.svg"
            :class="[
              'h-4 w-4 transform stroke-current text-ds-button-icon md:h-5 md:w-5',
              isDropDownOpen && 'rotate-180',
            ]"
          />
        </button>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <div
            v-if="isDropDownOpen"
            class="absolute right-0 w-max min-w-full rounded-b-lg bg-ds-foreground p-3 md:mt-0 md:pt-0"
          >
            <new-button
              @click="logout"
              type="secondary"
              class="w-full px-4 py-2 text-left text-sm transition-colors"
            >
              Log out
            </new-button>
          </div>
        </transition>
      </div>
    </div>
    <div v-if="!analyzing" class="flex items-start justify-between h-full">
      <div :class="leftBg" class="flex-1 h-full px-6 xl:px-0 hidden md:block">
        <slot name="left-content" />
      </div>
      <div :class="rightBg" class="flex-1 h-full relative px-6 xl:px-0">
        <slot name="right-content" />
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-full bg-white dark-mode:bg-dark-mode-80">
      <slot name="full-content" />
    </div>
    <new-button
      @click="toggleCrispChat"
      type="custom"
      class="chat-button bottom-14 bg-ds-button-secondary-background"
    >
      <inline-svg
        v-if="!showCrispChat"
        src="/img/icons/new/help.svg"
        class="h-6 w-6 stroke-ds-button-primary-background"
      />
      <inline-svg v-else src="/img/icons/close.svg" class="h-6 w-6" />
    </new-button>
  </div>
</template>

<script>
  import { checkTheme } from '@/util/checkTheme';
  import MobileMixin from '@/views/Mixins/MobileMixin';
  import fetchFirebaseConfig from '../../firebase';
  import { initCrisp } from '../../crisp';
  import { store } from '../../store';
  import LogoutMixin from '@/views/Mixins/LogoutMixin.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'onboarding-layout',
    components: {},
    mixins: [MobileMixin, LogoutMixin],
    props: {
      leftBg: {
        type: [String, Array],
        default: 'bg-white',
      },
      rightBg: {
        type: [String, Array],
        default: 'bg-white',
      },
      analyzing: {
        type: Boolean,
        default: false,
      },
      showLogo: {
        type: Boolean,
        default: false,
      },
      showSteps: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showCrispChat: false,
        isDropDownOpen: false,
      };
    },
    mounted() {
      const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      checkTheme(isDark);

      this.initCrispClient();
    },
    methods: {
      toggleCrispChat() {
        if (window.$crisp) {
          this.showCrispChat = !this.showCrispChat;
          this.showCrispChat
            ? window.$crisp.push(['do', 'chat:open'])
            : window.$crisp.push(['do', 'chat:close']);
        } else {
          console.error('Crisp is not loaded yet');
        }
      },
      initCrispClient() {
        fetchFirebaseConfig.then((firebaseConfig) => {
          const config = require('../../config').build(firebaseConfig);

          initCrisp(config.crispWebsiteID, store);
        });
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
  };
</script>
