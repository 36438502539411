<template>
  <div
    class="text-primary"
    :class="{ 'rounded-lg bg-ds-button-primary-background p-0.5': currentPlan.isRecommended }"
  >
    <p v-if="currentPlan.isRecommended" class="my-1 text-center text-base text-white">
      Recommended
    </p>
    <div
      class="border group relative space-y-6 overflow-hidden rounded-lg border-ds-outline-primary bg-ds-background p-6"
      :class="{
        'bg-ds-button-secondary-background md:w-94.5': currentPlan.isRecommended,
        'md:h-127 md:w-68': !currentPlan.isRecommended,
        'md:h-136': currentPlan.isRecommended && !showSelectButton,
      }"
    >
      <div class="flex flex-col gap-3">
        <p class="text-lg font-medium">{{ planLabel }}</p>
        <div class="flex flex-col gap-1">
          <p
            v-if="!isSubscriptionTypeMonthly"
            class="text-lg font-semi-medium text-ds-text-secondary-tertiary line-through"
          >
            {{ yearlyDiscountPrice }}$/month
          </p>
          <p class="text-3xl font-bold text-ds-button-icon">
            {{ planPrices }}
          </p>
          <p class="">VAT Excl.</p>
        </div>
      </div>

      <new-button
        v-if="(isMobile && !currentPlan.isRecommended) || !isMobile"
        class="w-full"
        @click="$emit('checkout')"
      >
        Start 7-day free trial
      </new-button>

      <div class="flex flex-col gap-5">
        <div
          v-if="isStarterOrBasicPlan || (currentPlan.isRecommended && showSelectButton)"
          class="flex w-full items-center justify-items-start gap-2"
        >
          <p class="text-base font-bold">Connect</p>
          <div
            v-for="social in socialMedia"
            :key="social.id"
            class="flex items-center justify-center rounded-full p-1"
            :class="[currentPlan.isRecommended ? 'bg-white' : 'bg-ds-button-secondary-background']"
          >
            <inline-svg class="h-3 w-3 text-blues-50" :src="social.icon" />
          </div>
        </div>
        <div v-else class="flex w-full items-center justify-items-start gap-3">
          <p class="text-base font-bold">Everything in {{ planBelowCurrent.label }}, plus:</p>
        </div>
        <div class="flex flex-col gap-3 text-base">
          <div class="flex w-full items-center gap-3">
            <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/schedule-icon.svg" />
            <p
              class="text-sm font-semi-medium"
              v-if="currentPlan.limits.scheduling.value === Infinity"
            >
              Unlimited Scheduling
            </p>
            <p class="text-sm font-semi-medium" v-else>Schedule up to {{ schedulingLimit }}</p>
          </div>
          <div class="flex w-full items-center gap-3">
            <inline-svg
              class="h-5 w-5 fill-transparent stroke-blues-50"
              src="/img/icons/new/avatar-outline.svg"
            />
            <span class="flex items-center gap-1">
              <p class="text-sm font-semi-medium">
                {{ currentPlan.limits.additionalAccounts.new + 1 }}
              </p>
              <inline-svg
                class="h-5 w-5 stroke-blues-50"
                src="/img/icons/new/socials/twitter.svg"
              />
              <p class="text-sm font-semi-medium">
                account{{ currentPlan.limits.additionalAccounts.new > 1 ? 's' : '' }}
              </p>
            </span>
          </div>
          <template v-if="isStarterOrBasicPlan || (currentPlan.isRecommended && showSelectButton)">
            <div class="flex w-full gap-3">
              <inline-svg class="h-5 w-5 text-blues-50" src="/img/icons/new/autoplug.svg" />
              <p>Autoplugs</p>
            </div>
            <div class="flex w-full gap-3">
              <inline-svg
                class="h-5 w-5 fill-transparent stroke-blues-50"
                src="/img/icons/new/folder-plus-outline.svg"
              />
              <p class="flex-1 text-sm font-semi-medium">Viral thread hooks and tweet templates</p>
            </div>
          </template>
          <div class="flex w-full items-center gap-3">
            <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/messages.svg" />
            <span class="flex items-center gap-1">
              <p class="text-sm font-semi-medium">{{ currentPlan.limits.dailyAutoDMs }}</p>
              <inline-svg
                class="h-5 w-5 stroke-blues-50"
                src="/img/icons/new/socials/twitter.svg"
              />
              <p class="text-sm font-semi-medium">Auto-DMs per day</p>
            </span>
          </div>
          <div class="flex w-full gap-3">
            <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/new/siren.svg" />
            <p class="flex-1">
              Increase engagement by watching up to
              <b>{{ currentPlan.limits.feed.watchedUsers }}</b> users &
              <b>{{ currentPlan.limits.feed.watchedSearchTerms }}</b> keywords
            </p>
          </div>
          <div
            v-if="isStarterOrBasicPlan || (currentPlan.isRecommended && showSelectButton)"
            class="flex w-full items-center gap-3"
          >
            <inline-svg
              class="h-5 w-5 stroke-blues-50"
              src="/img/icons/new/gumroad-sales-outline.svg"
            />
            <p class="flex-1 text-sm font-semi-medium">Automated Gumroad sales</p>
          </div>
          <div class="flex w-full items-center gap-3">
            <template v-if="isStarterOrBasicPlan">
              <inline-svg
                class="h-5 w-5 stroke-blues-50"
                src="/img/icons/new/chat-support-outline.svg"
              />
              <p class="flex-1 text-sm font-semi-medium">Email & Chat support</p>
            </template>
            <template v-if="currentPlan.name === 'premium'">
              <inline-svg
                class="h-5 w-5 stroke-blues-50"
                src="/img/icons/new/chat-support-outline.svg"
              />
              <p class="flex-1 text-sm font-semi-medium">Weekend support</p>
            </template>
          </div>
        </div>
      </div>

      <template v-if="!showSelectButton">
        <div
          class="absolute bottom-0 left-0 right-0 hidden h-16 items-center justify-center bg-gradient-to-t from-ds-background via-ds-background pb-3 md:flex"
          :class="{
            hidden: isMobile,
            'from-ds-button-secondary-background via-ds-button-secondary-background':
              currentPlan.isRecommended,
          }"
        />

        <div
          v-if="!isMobile"
          class="absolute bottom-0 left-0 right-0 flex h-16 items-center justify-center pb-3"
        >
          <new-button
            @click="$emit('open-compare-plans')"
            class="opacity-0 group-hover:opacity-100"
            :type="currentPlan.isRecommended ? 'primary' : 'secondary'"
          >
            Compare plans
          </new-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { getPlansBelowCurrentPlan } from '../../../functions/src/util/customerStatus.cjs';
  import { v4 as uuid } from 'uuid';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';

  export default {
    name: 'PlanCard',
    props: {
      currentPlan: Object,
      isSubscriptionTypeMonthly: Boolean,
      showSelectButton: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        socialMedia: [
          {
            id: uuid(),
            name: 'twitter',
            icon: '/img/icons/new/socials/twitter.svg',
          },
          {
            id: uuid(),
            name: 'facebook',
            icon: '/img/icons/new/socials/new-facebook.svg',
          },
          {
            id: uuid(),
            name: 'linkedIn',
            icon: '/img/icons/new/socials/new-linkedin.svg',
          },
          {
            id: uuid(),
            name: 'instagram',
            icon: '/img/icons/new/socials/new-instagram.svg',
          },
          {
            id: uuid(),
            name: 'threads',
            icon: '/img/icons/new/socials/threads.svg',
          },
          {
            id: uuid(),
            name: 'tiktok',
            icon: '/img/icons/new/socials/tiktok.svg',
          },
        ],
      };
    },
    computed: {
      planPrices() {
        if (!this.currentPlan.prices.monthly || !this.currentPlan.prices.monthly) return '';

        const { monthly, yearly } = this.currentPlan.prices;

        return this.isSubscriptionTypeMonthly
          ? `${monthly.displayPrice}/month`
          : `${yearly.displayPrice}/year`;
      },
      planLabel() {
        return this.currentPlan.name.toLocaleLowerCase() ===
          this.currentPlan.label.toLocaleLowerCase()
          ? this.currentPlan.label
          : `${this.currentPlan.label} (${this.currentPlan.name})`;
      },
      schedulingLimit() {
        return `${this.currentPlan.limits.scheduling.value} ${this.currentPlan.limits.scheduling.unit}`;
      },
      planBelowCurrent() {
        const plansBelowCurrent = getPlansBelowCurrentPlan(this.currentPlan.name);

        return plansBelowCurrent[plansBelowCurrent.length - 1];
      },
      isStarterOrBasicPlan() {
        return ['standard', 'basic'].includes(this.currentPlan.name);
      },
      yearlyDiscountPrice() {
        const monthlyPrice = this.currentPlan.prices.monthly.price.net;

        return monthlyPrice * 12;
      },
    },
    mixins: [MobileMixin],
  };
</script>
