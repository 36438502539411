<template>
  <div>
    <div
      class="app-create-modal border rounded-2xl border-gray-90 bg-white"
      v-if="source.type === 'retweet'"
    >
      <div
        class="border-b dark-mode-border-60 flex items-center justify-between border-gray-90 px-6 py-3"
      >
        <div>
          <span
            v-if="!source.hasErrors()"
            :set="(postingTime = source.postingTimeData(userProfile.timezone))"
            class="text-base"
          >
            <span class="d-none d-sm-inline">Retweeted</span>
            <a
              target="_blank"
              :href="`https://twitter.com/${userProfile.username}/status/${source.tweetId}`"
              class="text-capitalize text-dark-mode-15"
            >
              {{ postingTime.day }}<span class="d-none d-sm-inline"> at </span>
              {{ postingTime.time }}.
            </a>
          </span>
          <span
            v-else
            :set="(postingTime = source.postingTimeData(userProfile.timezone))"
            class="text-base"
          >
            <a
              target="_blank"
              :href="`https://twitter.com/${source.originalTweetInfo.username}/status/${source.tweetId}`"
              class="text-capitalize text-dark-mode-15"
            >
              This retweet
            </a>
            <span class="d-none d-sm-inline"> was scheduled for</span>
            {{ postingTime.day }}<span class="d-none d-sm-inline">&nbsp;at</span>
            {{ postingTime.time }}.
          </span>
        </div>
        <div class="card-action flex space-x-1 pr-1" v-if="!source.deleted">
          <base-dropdown
            menu-fixed
            data-cy="history-card-menu"
            class="nav-item"
            :direction="isLastItem ? 'up': 'down'"
            tag="div"
            title-tag="button"
            menuClasses="rounded-lg"
            title-classes="nav-link pr-0">
            <base-button
              class="focus:text-main-color-100 flex items-center justify-center cursor-pointer w-10 h-10 rounded-full outline-none focus:outline-none"
              @click.prevent=""
              slot="title-container"
            >
              <inline-svg src="/img/icons/moreOptions3dot.svg" />
            </base-button>
            <template>
              <base-button @click="retweet(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors()">
                <inline-svg src="/img/icons/retweet.svg" />
                <span>Retweet Now</span>
              </base-button>
              <base-button @click="scheduleThread(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors() && source.type !== 'retweet'">
                <inline-svg src="/img/icons/calendar.svg" />
                <span>Re-queue</span>
              </base-button>
              <base-button @click="scheduleRetweet(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors()">
                <inline-svg src="/img/icons/retweet.svg" />
                <inline-svg
                  v-if="userProfile.customerStatus === 'none'"
                  src="/img/icons/premium.svg"
                />
                <span>Schedule retweet once</span>
              </base-button>
              <base-button @click="scheduleRetweetRecurrently(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors()">
                <inline-svg src="/img/icons/retweet.svg" />
                <inline-svg
                  v-if="userProfile.customerStatus === 'none'"
                  src="/img/icons/premium.svg"
                />
                <span>Schedule multiple retweets</span>
              </base-button>
              <hr v-if="!source.hasErrors()"/>
              <base-button @click="deleteThread(source)" class="new-dropdown-item dark-mode-dropdown-item delete">
                <inline-svg stroke="#f56565" class="text-red-500" src="/img/icons/delete.svg" />
                <span class="text-red-500">Undo Retweet</span>
              </base-button>
            </template>
          </base-dropdown>
        </div>
      </div>
      <div class="retweet-container border-0">
        <div class="card-retweet" style="border-radius: 0; border: 0">
          <div class="flex items-center space-x-4 mb-4" v-if="source.originalTweetInfo">
            <a :href="'https://twitter.com/' + source.originalTweetInfo.username"
              target="_blank"
              class="rounded-full overflow-hidden block">
              <img :alt="source.originalTweetInfo.userDisplayName + '\'s profile picture'"
                  :src="source.originalTweetInfo.userProfilePictureURL" class="avatar-img">
            </a>
            <a :href="'https://twitter.com/' + source.originalTweetInfo.username" class="avatar-name" target="_blank">
              {{ source.originalTweetInfo.userDisplayName }}
            </a>
          </div>
          <div
            dir="auto"
            class="whitespace-pre-wrap break-words"
            v-html="formatTweet(source.originalTweetInfo.text)"
          />
          <div class="card-retweet-meta space-x-1">
            <inline-svg src="/img/icons/retweet.svg" />
            <a target="_blank"
              :href="`https://twitter.com/${source.originalTweetInfo.username}/status/${source.tweetId}`">
              View Original Tweet
            </a>
          </div>
        </div>
      </div>
      <history-card-error
        v-if="source.publishingError !== null"
        :error="{ ...source.publishingError, source: 'twitter' }"
        :thread="source"
        class="px-6 pb-6 pt-3"
      />
    </div>
    <div data-cy="history-card-tweet"
      class="bg-white app-create-modal border border-gray-90 rounded-2xl"
      v-if="source.tweets && source.tweets.length > 0"
      :class="{ 'drag-hovered-slot': isDragEntered }"
      :draggable="isCategory"
      @dragstart="dragStart(source, $event)"
      @dragend="dragEnd(source, $event)"
      @dragover="
        dragEnterNonEmptySlot(source, $event)
      "
      @dragleave="dragLeaveNonEmptySlot(source, $event)"
      @drop="dropNonEmptySlot(source, $event)"
    >
      <div class="px-6 py-3 border-b border-gray-90 flex items-center justify-between dark-mode-border-60"
        v-if="
          source.tweetIds &&
          source.tweetIds.length !== source.tweets.length &&
          !source.publishingError &&
          !source.instagramPublishingError &&
          !source.tiktokPublishingError
        "
      >
        <div class="card-posting">
          <span
            v-if="source.time && categoryId"
            :set="postingTime = source.postingTimeData(userProfile.timezone)"
          >
              Scheduled for {{ postingTime.day }}<span class="d-none d-sm-inline"> at </span> {{ postingTime.time }}.
          </span>
          <span v-else-if="source.isCancelled">
            Cancelled.
          </span>
          <div
            v-else-if="
              source.source === 'instagram-post' &&
              source.publishedToInstagram === true
            "
            :set="(postingTime = source.postingTimeData(userProfile.timezone))"
            class="flex items-center space-x-3"
          >
            <span class="text-base">Posted {{ postingTime.day }} at {{ postingTime.time }}</span>
            <div class="flex items-center justify-center w-8 h-8 bg-main-color-30 rounded-full">
              <svg
                  class="card-header-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
                  />
                  <path
                    d="M8.4995 0H3.4995C1.57 0 0 1.5705 0 3.5005V8.5005C0 10.43 1.5705 12 3.5005 12H8.5005C10.43 12 12 10.4295 12 8.4995V3.4995C12 1.57 10.4295 0 8.4995 0ZM6 9C4.3455 9 3 7.6545 3 6C3 4.3455 4.3455 3 6 3C7.6545 3 9 4.3455 9 6C9 7.6545 7.6545 9 6 9ZM9.5 3C9.224 3 9 2.776 9 2.5C9 2.224 9.224 2 9.5 2C9.776 2 10 2.224 10 2.5C10 2.776 9.776 3 9.5 3Z"
                  />
              </svg>
            </div>
          </div>

          <div
            v-else-if="source.source === 'linkedin-post'"
            :set="(postingTime = source.postingTimeData(userProfile.timezone))"
            class="flex items-center space-x-3"
          >
            <span class="text-base">Posted {{ postingTime.day }} at {{ postingTime.time }}</span>
            <div class="flex items-center justify-center w-8 h-8 bg-main-color-30 rounded-full">
              <svg
                  class="card-header-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.0588 3.15866H2.04361C1.87822 3.16863 1.71254 3.1444 1.55692 3.08747C1.40131 3.03055 1.2591 2.94215 1.13919 2.8278C1.01927 2.71345 0.924212 2.57561 0.859955 2.42288C0.795697 2.27015 0.763619 2.10581 0.765722 1.94012C0.767825 1.77444 0.804065 1.61096 0.872179 1.45991C0.940292 1.30886 1.03882 1.17347 1.1616 1.0622C1.28438 0.950937 1.42879 0.866178 1.58579 0.813221C1.7428 0.760264 1.90904 0.740246 2.07413 0.754416C2.24004 0.742401 2.40665 0.764841 2.56346 0.820325C2.72028 0.87581 2.86392 0.963139 2.98536 1.07682C3.10679 1.19051 3.2034 1.32808 3.26909 1.48091C3.33478 1.63373 3.36815 1.79849 3.36709 1.96483C3.36603 2.13117 3.33056 2.29549 3.26293 2.44746C3.19529 2.59944 3.09694 2.73577 2.97407 2.8479C2.85119 2.96002 2.70645 3.04551 2.54893 3.09899C2.39142 3.15247 2.22455 3.17279 2.0588 3.15866Z"
                  />
                  <path d="M3.14597 4.77734H0.988281V11.2504H3.14597V4.77734Z" />
                  <path
                    d="M8.80934 4.77734C8.44575 4.77834 8.08708 4.8615 7.76015 5.02061C7.43322 5.17973 7.14648 5.41068 6.92136 5.69621V4.77734H4.76367V11.2504H6.92136V8.28359C6.92136 7.99746 7.03503 7.72306 7.23735 7.52073C7.43967 7.31841 7.71408 7.20475 8.00021 7.20475C8.28634 7.20475 8.56074 7.31841 8.76307 7.52073C8.96539 7.72306 9.07905 7.99746 9.07905 8.28359V11.2504H11.2367V7.20475C11.2367 6.88598 11.174 6.57033 11.052 6.27582C10.93 5.98131 10.7512 5.71372 10.5258 5.48831C10.3004 5.26291 10.0328 5.08411 9.73827 4.96212C9.44376 4.84013 9.12811 4.77734 8.80934 4.77734Z"
                  />
                </svg>
            </div>
          </div>
          <div
              v-else-if="source.source === 'facebook-post'"
              :set="(postingTime = source.postingTimeData(userProfile.timezone))"
              class="flex items-center space-x-3"
            >
              <span class="text-base">Posted {{postingTime.day}} at {{postingTime.time}}</span>
              <div class="flex items-center justify-center w-8 h-8 bg-main-color-30 rounded-full">
                <svg
                  class="card-header-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.11262 2.74152H7.31112V0.714269C6.73083 0.653928 6.14778 0.624137 5.56437 0.625019C3.83037 0.625019 2.64462 1.68327 2.64462 3.62126V5.29151H0.6875V7.56101H2.64462V13.375H4.99062V7.56101H6.94137L7.23461 5.29151H4.99062V3.84439C4.99062 3.17502 5.16912 2.74152 6.11262 2.74152Z"
                  />
                </svg>
              </div>
            </div>

          <div
            v-else-if="source.source === 'threads-post'"
            :set="(postingTime = source.postingTimeData(userProfile.timezone))"
            class="flex items-center space-x-3"
          >
            <span class="text-base">Posted {{ postingTime.day }} at {{ postingTime.time }}</span>
            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-main-color-30">
              <inline-svg src="/img/icons/new/socials/threads.svg" />
            </div>
          </div>

          <div
            v-else-if="source.source === 'tiktok-post'"
            :set="(postingTime = source.postingTimeData(userProfile.timezone))"
            class="flex items-center space-x-3"
          >
            <span class="text-base">Posted {{ postingTime.day }} at {{ postingTime.time }}</span>
            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-main-color-30">
              <inline-svg src="/img/icons/new/socials/tiktok.svg" class="text-white" />
            </div>
          </div>

          <span v-else>
            Posting...
          </span>
        </div>
        <div
          class="card-action flex space-x-1 pr-1"
          v-if="
            !source.deleted &&
            !['instagram-post', 'linkedin-post', 'facebook-post', 'tiktok-post', 'threads-post'].includes(
              source.source
            )
          "
        >
          <tooltip v-if="canPostNow" content="Post Now">
            <base-button data-cy="history-card-post-now" @click="postNow" class="btn icon">
              <inline-svg
                src="/img/icons/post-now-icon.svg"
                class="h-5 w-5 stroke-current hover:stroke-main-color-100"
              />
            </base-button>
          </tooltip>
          <tooltip v-if="!source.isCancelled" content="Cancel posting of this thread">
            <base-button
              @click="cancelThreadPosting(source)"
              class="btn icon cancel">
              <inline-svg src="/img/icons/close.svg" />
            </base-button>
          </tooltip>

          <tooltip v-else-if="source.type !== 'retweet'" content="Re-queue">
            <base-button
              @click="scheduleThread(source)"
              class="btn icon transparent">
              <inline-svg src="/img/icons/calendar.svg" />
            </base-button>
          </tooltip>
        </div>
      </div>
      <div class="px-6 py-3 border-b border-gray-90 flex items-center justify-between dark-mode-border-60"
        v-else-if="!source.hasErrors()"
        :class="{ 'cursor-move': isCategory }"
      >
        <div :set="postingTime = source.postingTimeData(userProfile.timezone)" class="text-base">
          <span v-if="!source.time && !source.tweetIds && categoryId">
            This category post has not been posted yet.
          </span>
          <span v-else>
            Posted
            <span v-if="source.recurrentPostRef && $route.meta.id !== 'history_categories'" class="text-base">
              from recurrent post calendar
            </span>
            <a
              target="_blank"
              :href="`https://twitter.com/${userProfile.username}/status/${source.tweets[0].tweetId}`"
              class="text-dark-mode-15 text-capitalize">
            {{ postingTime.day }} at {{ postingTime.time }}
            </a>
          </span>
          <span class="font-bold text-red-500 cursor-default" v-if="source.isRecurrentPostDisabled && categoryId">
            <tooltip content="This post will be ignored when publishing recurrent posts from this category.">
              <span>(disabled)</span>
            </tooltip>
          </span>
          <span
            class="text-secondary"
            :set="postingTime = source.lastClonePostedTimeData(userProfile.timezone)"
            v-if="
              source.lastClonePostedTime &&
              $route.meta.id === 'history_categories' &&
              source.time < source.lastClonePostedTime
            "
          >
            <br />
            Last posted {{ postingTime.day }} at {{ postingTime.time }}
          </span>
        </div>
        <div class="card-action flex space-x-1 pr-1" v-if="!source.deleted">
          <template v-if="!source.isCloned">
            <tooltip :content="!source.isFavorite ? 'Set as an Evergreen Post' : 'Unset as an Evergreen Post'">
              <base-button
                data-cy="history-card-evergreen"
                @click="addToEvergreenQueue(source)"
                class="flex items-center justify-center cursor-pointer w-10 h-10 rounded-full outline-none focus:outline-none"
                :class="source.isFavorite ? 'text-green-500' : ''"
              >
                <inline-svg :src="source.isFavorite ? '/img/icons/star.svg' : '/img/icons/star-empty.svg'" />
              </base-button>
            </tooltip>
          </template>
          <base-dropdown
            menu-fixed
            data-cy="history-card-menu"
            class="nav-item"
            :direction="isLastItem ? 'up': 'down'"
            tag="div"
            title-tag="button"
            menuClasses="rounded-lg"
            title-classes="nav-link pr-0">
            <base-button
              class="focus:text-main-color-100 flex items-center justify-center cursor-pointer w-10 h-10 rounded-full outline-none focus:outline-none"
              @click.prevent=""
              slot="title-container"
            >
              <inline-svg src="/img/icons/moreOptions3dot.svg" />
            </base-button>
            <template>
              <base-button data-cy="history-card-retweet-now" @click="retweet(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors() && source.time">
                <inline-svg src="/img/icons/new-retweet.svg" />
                <span>Retweet Now</span>
              </base-button>
              <base-button data-cy="history-card-re-queue" @click="scheduleThread(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors() && source.type !== 'retweet'">
                <inline-svg src="/img/icons/calendar.svg" />
                <span>Re-queue</span>
              </base-button>
              <base-button v-show="source.recurrentPostRef && categoryId" @click="toggleDisabled(source)" class="new-dropdown-item dark-mode-dropdown-item">
                <inline-svg :src="`/img/icons/${source.isRecurrentPostDisabled ? 'post' : 'close'}.svg`" />
                <span>{{ source.isRecurrentPostDisabled ? 'Enable' : 'Disable' }} post</span>
              </base-button>
              <base-button
                v-show="source.recurrentPostRef && categoryId"
                @click="sendToNewComposer(source)"
                class="new-dropdown-item dark-mode-dropdown-item"
              >
                <inline-svg src="/img/icons/exchange.svg" />
                <span>Replace post</span>
              </base-button>
              <base-button data-cy="history-card-schedule-once" @click="scheduleRetweet(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors() && source.time">
                <inline-svg src="/img/icons/new-retweet.svg" />
                <inline-svg
                  v-if="userProfile.customerStatus === 'none'"
                  src="/img/icons/premium.svg"
                />
                <span>Schedule retweet once</span>
              </base-button>
              <base-button data-cy="history-card-schedule-repeat" @click="scheduleRetweetRecurrently(source)" class="new-dropdown-item dark-mode-dropdown-item" v-if="!source.hasErrors() && source.time">
                <inline-svg src="/img/icons/new-retweet.svg" />
                <inline-svg
                  v-if="userProfile.customerStatus === 'none'"
                  src="/img/icons/premium.svg"
                />
                <span>Schedule multiple retweets</span>
              </base-button>
              <template v-if="!source.recurrentPostRef">
                <hr />
                <base-button v-show="categoryId" @click="toggleDisabled(source)" class="new-dropdown-item dark-mode-dropdown-item">
                  <inline-svg :src="`/img/icons/${source.isRecurrentPostDisabled ? 'post' : 'close'}.svg`" />
                  <span>{{ source.isRecurrentPostDisabled ? 'Enable' : 'Disable' }} post</span>
                </base-button>
                <base-button
                  v-if="categoryId"
                  data-cy="history-card-replace-post"
                  @click="sendToNewComposer(source)"
                  class="new-dropdown-item dark-mode-dropdown-item"
                >
                  <inline-svg src="/img/icons/exchange.svg" />
                  <span>Replace post</span>
                </base-button>
                <base-button data-cy="history-card-edit-categories" @click="showEditPostCategoriesModal" class="new-dropdown-item dark-mode-dropdown-item">
                  <inline-svg src="/img/icons/template.svg" />
                  <span>Edit post categories</span>
                </base-button>
              </template>
              <hr v-if="!source.hasErrors()"/>
              <base-button
                v-if="source.autoDM"
                @click="disableCampaign(source.id)"
                 class="new-dropdown-item dark-mode-dropdown-item"
                :disabled="userProfile.customerStatus === 'none'"
              >
                <inline-svg stroke="#f56565" class="text-red-500" src="/img/icons/close.svg" />
                <span class="text-red-500">Disable campaign</span>
              </base-button>
              <base-button data-cy="history-card-delete-post" @click="deleteThread(source)" class="new-dropdown-item dark-mode-dropdown-item delete" :disabled="userProfile.customerStatus === 'none'">
                <inline-svg stroke="#f56565" class="text-red-500" src="/img/icons/delete.svg" />
                <span class="text-red-500">Delete Post</span>
              </base-button>
            </template>
          </base-dropdown>
        </div>
      </div>
      <div v-if="source.tweets.length > 1">
        <time-line type="one-side px-10 py-6">
          <time-line-item v-bind:key="tweet.id"
            v-for="(tweet, index) in source.tweets"
            badge-type="secondary"
            additionalClasses="row"
            :title="String(tweet.count + 1)"
            class="text-base">
            <div
              class="whitespace-pre-wrap break-words"
              v-html="formatTweet(tweet.status)"
              dir="auto"
            />
            <div
              v-if="tweet.quoteTweetData"
              class="card-child-bg p-4 rounded-md mt-6 space-y-2 app-queue-tweet my-3"
              :class="{
                'mt-3': tweet.status && tweet.status.length > 0,
                'mb-3': tweet.media && tweet.media.length > 0,
              }"
            >
              <div class="flex space-x-2 items-center">
                <img :src="tweet.quoteTweetData.userProfilePictureURL" class="rounded-full h-8 w-8" />
                <span class="text-dark-mode-10 text-base">@{{ tweet.quoteTweetData.username }}</span>
              </div>
              <div
                class="text-base lg:text-lg whitespace-pre-wrap break-words"
                v-html="formatTweet(tweet.quoteTweetData.text)"
                dir="auto"
              />
            </div>
            <div
              v-if="source.hasThreadFinisherTweet && tweet.count === source.tweets.length - 1"
              class="card-child-bg p-4 rounded-md mt-6 px-4 space-y-2 app-queue-tweet my-3">
              <div class="flex space-x-2 items-center">
                <img :src="userProfile.photoURL" class="rounded-full h-8 w-8" />
                <span class="text-dark-mode-15">@{{ userProfile.username }}</span>
              </div>
              <div
                class="text-base lg:text-lg whitespace-pre-wrap break-words"
                v-html="formatTweet(source.tweets[0].status)"
                dir="auto"
              />
            </div>
            <span
              v-if="shouldTweetPublishStatusBeDisplayed(source)"
              class="text-xs text-muted block mt-2 flex items-center space-x-1"
            >
              <div class="flex items-center space-x-1" v-if="tweet.published">
                <span>Published</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"
                  />
                </svg>
              </div>
              <div
                v-else-if="!tweet.published && source.isCancelled"
                class="flex items-center space-x-1"
              >
                <span>Cancelled</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                  <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <span
                v-else
              >Tweet will be published at {{ displayTweetPublishTime(tweet, source) }}</span>
            </span>
            <queue-thumbnail
              :media="getMedia(source, index)"
              :marginTop="tweet.status.length > 0"
            />
            <poll-options :tweet="tweet" />
          </time-line-item>
        </time-line>
      </div>
      <div class="px-6 py-4" v-if="source.tweets.length === 1">
        <div class="row">
          <div class="card-reply mb-5" v-if="source.replyToTweetId">
            <div class="flex items-center space-x-4 mb-4" v-if="source.replyToTweetInfo">
              <a
                :href="'https://twitter.com/' + source.replyToTweetInfo.username"
                target="_blank"
                class="w-6 h-6 rounded-full overflow-hidden"
              >
                <img
                  :alt="source.replyToTweetInfo.userDisplayName + '\'s profile picture'"
                  :src="source.replyToTweetInfo.userProfilePictureURL"
                />
              </a>
              <a :href="'https://twitter.com/' + source.replyToTweetInfo.username" target="_blank">
                {{ source.replyToTweetInfo.userDisplayName }}
              </a>
            </div>
            <div
              dir="auto"
              class="whitespace-pre-wrap break-words text-base"
              v-html="formatTweet(source.replyToTweetInfo.text)"
            />
            <div class="card-reply-meta space-x-1">
              <!-- reply.svg -->
              <inline-svg src="/img/icons/reply.svg" />
              <a
                target="_blank"
                :href="`https://twitter.com/${source.replyToTweetInfo.username}/status/${source.replyToTweetId}`"
              >
                In reply to <span>{{ source.replyToTweetInfo.username }}</span>
              </a>
            </div>
          </div>

          <div class="reply-line" v-if="source.replyToTweetId"></div>

          <div
            class="whitespace-pre-wrap break-words text-base"
            v-html="getPostStatus(source)"
            dir="auto"
          />

          <div
            v-if="source.tweets[0].quoteTweetData"
            class="card-child-bg p-4 rounded-md mt-6 px-4 space-y-2 app-queue-tweet my-3"
            :class="{
              'mt-3': source.tweets[0].status && source.tweets[0].status.length > 0,
              'mb-3': source.tweets[0].media && source.tweets[0].media.length > 0,
            }"
          >
            <div class="flex space-x-2 items-center">
              <img :src="source.tweets[0].quoteTweetData.userProfilePictureURL" class="avatar-img" alt="user profile picture" />
              <span class="avatar-username">@{{ source.tweets[0].quoteTweetData.username }}</span>
            </div>
            <div
              class="whitespace-pre-wrap break-words text-base"
              v-html="formatTweet(source.tweets[0].quoteTweetData.text)"
              dir="auto"
            />
          </div>

          <div
            v-for="content in source.tweetshotContent"
            :key="content.tweetId"
            class="card-child-bg mx-auto group relative my-6 flex max-w-lg flex-col items-center gap-y-5 rounded-2xl border-gray-60 bg-gray-10 py-4 px-6">
            <div class="relative w-full">
              <div class="my-2 flex gap-x-2 items-center">
                <img class="avatar-img" :src="userProfile.photoURL" alt="avatar" />
                <p class="avatar-name">
                  {{ userProfile.name }}
                </p>
                <span class="avatar-username">@{{ userProfile.username }}</span>
              </div>
              <p
                class="mt-3 max-w-full break-words px-1 text-base"
                v-html="content.tweetText"
                dir="auto"
              />
            </div>
          </div>

          <queue-thumbnail
            :media="getMedia(source, 0)"
            :marginTop="source.tweets[0].status > 0"
          />
          <poll-options :tweet="source.tweets[0]" />
        </div>
      </div>
      <div v-if="source.tweetMetricsUpdatedAt && postImpressions !== null" class="py-3 px-6 border-t border-gray-90 dark-mode-border-60">
        <tweets-metrics-card-footer :thread="source" :recurrentPostsMetrics="recurrentPostsMetrics" />
      </div>
      <div
        v-if="threadErrors.length > 0"
      >
        <history-card-error
          v-for="error of threadErrors"
          :key="error.source"
          :error="error"
          :thread="source"
          :retry="canRetryPost(error)"
          @retry="
            error.source === 'twitter'
              ? editOnNewComposer(source)
              : retryThread(source.id, error.source)
          "
          @send-to-drafts="sendToDrafts(source)"
          class="pt-3 pb-6 px-6"
        />
      </div>
    </div>
    <schedule-retweet-modal
      v-if="isScheduleRetweetModalVisible"
      :post="source"
      @close="closeScheduleRetweetModal"
      :show="isScheduleRetweetModalVisible" />
    <schedule-retweet-recurrently-modal
      v-if="isScheduleRetweetRecurrentlyModalVisible"
      :post="source"
      @close="closeScheduleRetweetRecurrentlyModal"
      :show="isScheduleRetweetRecurrentlyModalVisible" />
    <upgrade-to-premium-pop-up
      :show="showUpgradeToPremiumPopUp"
      :content="`Upgrade to the ${getPlanLabel(
        'premium'
      )} plan to unlock sharing any of your tweets to Instagram and ton of other powerful features!`"
      @continue-operation="continuePremiumFeature"
      @close="showUpgradeToPremiumPopUp = false"
    />
    <edit-post-categories-modal
      v-if="isEditPostCategoriesModalVisible"
      :post="source"
      @close="closeEditPostCategoriesModal"
      :show="isEditPostCategoriesModalVisible" />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { v1 as uuidv1 } from 'uuid';
  import { formatTweet } from '@/util/formatTweet';
  import QueueThumbnail from '../views/QueueThumbnail';
  import PollOptions from './PollOptions';
  import { TimeLine, TimeLineItem } from '@/components';
  import TweetsMetricsCardFooter from '@/components/Cards/TweetsMetricsCardFooter';
  import ScheduleRetweetModal from '@/views/Modals/ScheduleRetweetModal';
  import ScheduleRetweetRecurrentlyModal from '@/views/Modals/ScheduleRetweetRecurrentlyModal';
  import controller from '@/controller';
  import DeletePostMixin from '@/views/Mixins/DeletePostMixin';
  import moment from 'moment';
  import EditPostCategoriesModal from '@/views/Modals/EditPostCategoriesModal';
  import HistoryCardError from '@/components/HistoryCardError';
  import dao from '@/dao';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import InspirationTweetsMixin from '../views/Mixins/InspirationTweetsMixin.vue';
  import { Thread } from '../models/Thread';
  import GetHypefurySubscriptionMixin from '@/views/Mixins/GetHypefurySubscriptionMixin.vue';
  import PostStatusMixin from '../views/Mixins/PostStatusMixin.vue';
  import lodash from 'lodash';
  import DragAndDropMixin from '../views/Mixins/DragAndDropMixin.vue';
  import { store } from '@/store';
  import { EventBus } from '@/event-bus';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'history-item',
    components: {
      PollOptions,
      QueueThumbnail,
      EditPostCategoriesModal,
      ScheduleRetweetModal,
      ScheduleRetweetRecurrentlyModal,
      TimeLine,
      TimeLineItem,
      TweetsMetricsCardFooter,
      HistoryCardError,
    },
    props: {
      source: {
        type: Object
      },
      categoryId: {
        type: String,
        default: () => null
      },
      recurrentPostsMetrics: {
        type: Object,
        default: () => ({})
      },
      draggedPost: {
        type: Object,
      },
      threadsLength: {
        type: Number,
      },
      index: {
        type: Number,
      },
    },
    data() {
      return {
        isScheduleRetweetModalVisible: false,
        isScheduleRetweetRecurrentlyModalVisible: false,
        showUpgradeToPremiumPopUp: false,
        isEditPostCategoriesModalVisible: false,
        categoryPostStatus: '',
        selectedRecurrentPost: null,
        isCategory: Boolean(this.categoryId),
      }
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule', 'categoriesPosts']),
      threadHasNoErrors() {
        return (
          this.source.tweetIds &&
          this.source.tweetIds.length !== this.source.tweets.length &&
          !this.source.publishingError &&
          !this.source.instagramPublishingError &&
          !this.source.facebookPublishingError &&
          !this.source.linkedInPublishingError &&
          !this.source.tiktokPublishingError
        );
      },
      threadErrors() {
        const errors = [];

        if (
          this.source.publishingError &&
          !['linkedin-post', 'instagram-post', 'facebook-post', 'tiktok-post'].includes(this.source.source)
        ) {
          errors.push({ ...this.source.publishingError[0], source: 'twitter' });
        }

        if (this.source.facebookPublishingError || this.source.source === 'facebook-post') {
          errors.push({ ...this.source.facebookPublishingError, source: 'facebook' });
        }

        if (this.source.instagramPublishingError || this.source.source === 'instagram-post') {
          errors.push({ ...this.source.instagramPublishingError, source: 'instagram' });
        }

        if (this.source.linkedInPublishingError || this.source.source === 'linkedin-post') {
          errors.push({ ...this.source.linkedInPublishingError, source: 'linkedIn' });
        }

        if (
          this.source.tiktokPublishingError ||
          (this.source.tiktokPublishingError && this.source.source === 'tiktok-post')
        ) {
          errors.push({ ...this.source.tiktokPublishingError[0], source: 'tiktok' });
        }

        return errors;
      },
      isLastItem() {
        return this.threadsLength === this.index + 1;
      },
      postImpressions() {
        return lodash.get(this.source, 'tweets.0.impressions', null);
      },
      canPostNow() {
        return (
          this.source.delayBetweenTweets && this.source.tweets.some((tweet) => !tweet.published)
        );
      },
    },
    methods: {
      getMedia(source, index) {
        if (source.tweetReel && source.tweetReel.outputFileUrl) {
          return [{ url: source.tweetReel.outputFileUrl, type: 'reel' }];
        } else {
          return source.tweets[index].media;
        }
      },
      addToEvergreenQueue(thread) {
        thread.toggleFavorite();
        this.updateInspirationTweet(thread.id, { isFavorite: thread.isFavorite });
      },
      async toggleDisabled(thread) {
        const nextStatus = thread.isRecurrentPostDisabled ? 'enable' : 'disable';
        try {
          await thread.toggleDisabled();

          if (Object.keys(this.categoriesPosts).length > 0) {
            if (nextStatus === 'enable') {
              store.dispatch('addCategoryPost', thread);
            } else {
              store.dispatch('removeCategoryPost', thread);
            }
          }

          this.$notify({ type: 'success', message: `Thread ${nextStatus}d successfully!` });
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occured while trying to ${nextStatus} thread.`,
            type: 'warning',
          });
        }
      },
      async retryThread(threadId, service) {
        const _setServiceToRetry = async () => {
          try {
            await dao.posts.setServiceToRetry(threadId, service);
            this.$notify({
              type: 'success',
              message: `Retrying posting to ${lodash.capitalize(service)}!`,
            });
          } catch (error) {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occured while trying to retry the post.`,
              type: 'warning',
            });
          }
        }

        this.swalModal({
          title: `Retry ${lodash.capitalize(service)} post`,
          html: `Are you sure you want to retry this ${lodash.capitalize(service)} post now?`,
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          preConfirm: _setServiceToRetry,
        });
      },
      sendToNewComposer(thread) {
        EventBus.$emit('open-new-composer', {
          categories: [{ id: this.categoryId }],
          post: thread,
          isReplacingPost: true,
          postType: 'recurrent',
          source: {
            name: 'Category Posts',
            params: {
              categoryId: this.categoryId,
            },
          },
        });
      },
      editOnNewComposer(thread) {
        if (lodash.get(thread, 'autoDM.enabled')) {
          this.$router.push({ name: 'Auto DMs', params: { post: thread } });
        } else {
          EventBus.$emit('open-new-composer', {
            categoryId: this.categoryId,
            post: thread,
            postType: 'twitter',
          });
        }
      },
      showReplaceRecurrentPostModal(thread) {
        this.$parent.$parent.$emit('show-replace-recurrent-post-modal', thread);
      },
      showEditPostCategoriesModal() {
        this.isEditPostCategoriesModalVisible = true;
      },
      canRetryPost(error) {
        return (
          error.source === 'twitter' ||
          error.code === 500 ||
          (error.source === 'linkedIn' && error.message && error.message.includes('expired')) ||
          // For more info: https://developers.facebook.com/docs/graph-api/guides/error-handling
          ((error.source === 'facebook' || error.source === 'instagram') &&
            [1, 2, 4, 17, 190].includes(error.code))
        );
      },
      closeEditPostCategoriesModal() {
        this.isEditPostCategoriesModalVisible = false;
      },
      closeScheduleRetweetModal() {
        this.isScheduleRetweetModalVisible = false;
      },
      closeScheduleRetweetRecurrentlyModal() {
        this.isScheduleRetweetRecurrentlyModalVisible = false;
      },
      continuePremiumFeature() {
        this.$router.push(`/instagram/share/${this.userProfile.uid}/${this.source.id}`);
      },
      displayTweetPublishTime(tweet, thread) {
        const delayInMinutes = thread.delayBetweenTweets * tweet.count;
        const tweetPublishTime = moment(thread.time).add(delayInMinutes, 'minutes').format('h:mm A');

        return tweetPublishTime;
      },
      async sendToDrafts(post) {
        try {
          await controller.posts.sendToDrafts(this.currentUser, this.userProfile.uid, post.id);
          this.$notify({
            type: 'success',
            message: 'Post moved to Drafts!',
          });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occured while trying to move the post to Drafts.',
            type: 'warning',
          });
        }
      },
      formatTweet(tweet) {
        return formatTweet(tweet);
      },
      shareOnInstagram() {
        this.$router.push(`/instagram/share/${this.userProfile.uid}/${this.source.id}`);
      },
      shouldTweetPublishStatusBeDisplayed(thread) {
        return (
          Array.isArray(thread.tweetIds) &&
          Array.isArray(thread.tweets) &&
          thread.tweetIds.length !== thread.tweets.length &&
          !thread.publishingError &&
          (thread.delayBetweenTweets || thread.isCancelled)
        );
      },
      retweet(thread) {
        this.$eventStore.history('Dropdown: Retweet Now');
        const self = this;

        if (this.userProfile.customerStatus === 'none') {
          EventBus.$emit('open-upgrade-plan-modal', {
            planName: 'Starter',
            featureName: 'Retweet',
            featureAdvantage: 'start retweeting',
            isUserScheduling: false,
          });

          return;
        }

        return controller.retweet(this.currentUser, thread.id, this.userProfile.uid)
          .then(() => {
            self.$notify({type: 'success', message: 'Tweet successfully retweeted!'});
          })
          .catch(error => {
            if (error.response.status === 404) {
              self.$notify({type: 'warning', message: 'Tweet not found. Did you maybe delete it from Twitter?'});
            } else {
              self.$notify({type: 'danger', message: 'An unknown error has occurred. Couldn\'t retweet.'});
            }
          });
      },
      async scheduleThread(post) {
        this.$eventStore.history('Dropdown: Re-queue');
        const time = this.schedule.getNextTimeSlot();

        const updatedPostTweets = lodash.get(post, 'tweets', []).map((tweet) => {
          const updatedTweet = {
            count: tweet.count,
            media: tweet.media,
            published: false,
            status: tweet.status,
            guid: uuidv1(),
          };
          if (tweet.quoteTweetData) {
            updatedTweet.quoteTweetData = tweet.quoteTweetData;
          }
          return updatedTweet;
        });

        const thread = Thread.newThread({
          time,
          tweets: updatedPostTweets,
          user: this.userProfile.uid,
          categories: [],
          hasThreadFinisherTweet: post.hasThreadFinisherTweet
        });

        await thread.saveToFirestore(this.currentUser, this.userProfile.uid, this.timezone, this.$eventStore);
        this.$eventStore.scheduleThreadFromHistory();
        this.$notify({ type: 'success', message: 'Post successfully re-queued!' });

        if (this.userProfile.customerStatus === 'none') {
          EventBus.$emit('open-upgrade-plan-modal', {
            planName: 'Starter',
            isUserScheduling: true,
          });
        }
      },
      scheduleRetweet() {
        this.$eventStore.history('Dropdown: Schedule retweet once');
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp('Schedule Retweet', 'schedule a retweet');
        } else {
          this.isScheduleRetweetModalVisible = true;
        }
      },
      scheduleRetweetRecurrently() {
        this.$eventStore.history('Dropdown: Schedule multiple retweets');
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp(
            'Schedule Retweet Recurrently',
            'schedule a retweet recurrently',
          );
        } else {
          this.isScheduleRetweetRecurrentlyModalVisible = true;
        }
      },
      cancelThreadPosting(post) {
        this.swalModal({
          title: 'Cancel thread',
          html: `This will cancel the posting of this thread. Are you sure?`,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes, go ahead',
          preConfirm: async () => {
            await dao.posts.cancelPostingOfThreadWithDelayBetweenTweets(post.id)
            this.$notify({ type: 'success', message: 'Your thread has been cancelled!' });
          },
        });
      },
      disableCampaign() {
        const self = this;
        const wasStartedMoreThanThreeDaysAgo = moment(self.source.time).isBefore(
          moment().subtract(3, 'days')
        );
        if (wasStartedMoreThanThreeDaysAgo) {
          self.swalModal({
            title: 'Error',
            html: `This DM campaign has already ended!`,
            confirmButtonText: 'Okay',
          });
          return;
        }
        if (self.source.autoDM.isDisabled) {
          self.swalModal({
            title: 'Error',
            html: `This DM campaign has already been disabled!`,
            confirmButtonText: 'Okay',
          });
          return;
        }
        self.swalModal({
          title: 'Disable campaign',
          html: `Are you sure you want to disable this DM campaign?`,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes, go ahead',
          preConfirm: async () => {
            try {
              await dao.disableCampaign(self.source.id);
              self.$notify({ type: 'success', message: 'Campaign disabled!' });
            } catch (error) {
              self.swalModal({
                title: 'Sorry',
                text: `An error has occurred while disabling this DM campaign.`,
                type: 'warning',
              });
            }
          },
        });
      },
      async postNow() {
        this.swalModal({
          title: `Post Now`,
          text: `Are you sure you want to post to Twitter now?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, post now',
          preConfirm: async () => {
            try {
              await dao.posts.updateThreadDelayBetweenTweets(this.source.id);
              this.$notify({ type: 'success', message: 'Thread on its way!' });
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: `An error has occurred while deleting the post.`,
                type: 'warning',
              });
              console.error(error);
            }
          },
        });
      },
    },
    mixins: [
      DeletePostMixin,
      SwalModalMixin,
      InspirationTweetsMixin,
      GetHypefurySubscriptionMixin,
      PostStatusMixin,
      DragAndDropMixin,
      CustomerStatusMixin,
    ],
  }
</script>
<style scoped lang="scss">
  .card-title {
    min-height: 2.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .card-action .icon {
    margin: 0!important;
  }
  .tweetshot {
    position: relative;
    background: var(--tweetshot);
    ::v-deep a {
      color: inherit;
    }
    .arrow {
      width: 0;
      height: 0;
      border-top: 16px solid var(--tweetshot);
      border-bottom: 16px solid transparent;
      border-left: 16px solid var(--tweetshot);
      position: absolute;
      bottom: -16px;
      clip-path: polygon(100% 50%, 0 50%, 0 100%);
    }
  }
</style>
