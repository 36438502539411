const moment = require('moment');

module.exports.wasUserCreatedBeforePlansChanges = (userCreatedAt) => {
  if (!userCreatedAt) {
    return false;
  }

  const PLANS_CHANGES_DATE = moment('2024-12-26').startOf('day');
  return moment(userCreatedAt.toDate()).isBefore(PLANS_CHANGES_DATE);
};
