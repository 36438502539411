<template>
  <div class="relative">
    <div class="mb-5 grid grid-cols-1 py-2 md:mb-6 md:grid-flow-row 2xl:mx-64">
      <div class="row-start-1 flex items-center gap-x-2">
        <inline-svg src="/img/icons/new/new-video.svg" class="stroke-ds-button-icon" />
        <h2 class="dashboard-title text-ds-text-primary">Tweet to Reel</h2>
        <span
          class="border rounded-2xl border-ds-outline-primary px-3 py-1 text-sm text-ds-text-primary"
          >Beta</span
        >
      </div>
      <div
        class="flex w-full items-center justify-between gap-x-2"
        :class="{
          'items-center': videosLeft > 1,
          'items-start': videosLeft <= 1,
        }"
      >
        <div class="flex items-center gap-x-2">
          <inline-svg src="/img/icons/new/video-credits.svg" class="text-ds-button-icon" />
          <p class="text-ds-button-icon">{{ videosLeft }}/30 videos left this month</p>
        </div>
        <!-- <new-button
          @click="triggerRegenerateRandomReels"
          size="sm"
          type="secondary"
          inactive
          class="row-start-3 md:row-start-1 md:ml-auto"
        >
          <inline-svg src="/img/icons/ai-request.svg" class="mr-1 stroke-ds-button-primary-hover" />
          <span class="text-ds-button-primary-hover"
            ><span class="text-ds-button-primary-hover">Regenerate</span></span
          ></new-button
        > -->
      </div>
      <div
        class="row-start-2 mt-5 grid grid-cols-1 grid-rows-4 gap-y-4 md:col-span-2 md:mt-6 md:flex md:flex-row md:gap-y-0"
      >
        <transition-group
          enter-active-class="transform  transition-all duration-500 ease-in-out"
          :enter-class="`opacity-0 ${
            animationDirection === 'down' ? 'translate-y-10' : '-translate-y-10'
          }`"
          enter-to-class="opacity-100"
          leave-active-class=" transform transition-all duration-500 ease-in-out"
          :leave-class="`opacity-100 ${
            animationDirection === 'down' ? 'translate-y-0' : 'translate-y-0'
          }`"
          leave-to-class="opacity-0"
          tag="div"
          class="relative row-span-2 w-full md:w-1/3"
          :style="`isolation: isolate; min-height: ${tweetCardsContainerHeight}px;`"
        >
          <tweet-variation-card
            v-for="(item, index) in formattedPopularTweets"
            :key="`${item.docId}-${index}`"
            ref="tweet-card"
            class="absolute h-full bg-ds-foreground"
            :class="{
              'inset-0 z-50': index === 0,
              'top-2 z-40 scale-95 transform': index === 2,
              'top-4 z-30 scale-90 transform': index === 1,
            }"
            size="lg"
          >
            <div
              v-if="item.categories.length"
              class="absolute right-2 top-2 flex items-center gap-x-2 rounded-2xl bg-ds-button-secondary-background px-2 py-1 text-ds-text-primary"
            >
              <div
                class="h-2 w-2 rounded-full"
                :style="'background-color: ' + item.categories[0].color + ' ;'"
              ></div>
              <new-drop-down
                v-if="item.categories.length > 1"
                buttonType="custom"
                :padded="false"
                buttonClasses="px-0"
                fixed
              >
                <template #button>
                  <span class="mr-2">{{ item.categories[0].name }} </span
                  ><span class="hidden lg:block">+{{ item.categories.length }}</span>
                </template>
                <div
                  class="flex w-full flex-col items-start justify-center gap-y-3 p-4 md:max-w-xxs"
                >
                  <div
                    class="flex items-center gap-x-3"
                    v-for="(category, index) in item.categories"
                    :key="index"
                  >
                    <div
                      class="h-3 w-3 rounded-full"
                      :style="'background-color: ' + item.categories[index].color + ' ;'"
                    ></div>
                    <div class="text-ds-button-icon" >{{ category.name }}</div>
                  </div>
                </div>
              </new-drop-down>
              <span v-else>{{ item.categories[0].name }} </span>
            </div>
            <div class="flex h-full flex-col gap-5">
              <div class="card-avatar-title mb-2 flex items-center gap-x-2 gap-y-2">
                <img :src="userProfile.photoURL" class="avatar-img-sm" alt="user photo" />
                <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                  <p class="truncate text-ellipsis max-w-full font-bold text-primary">
                    {{ userProfile.name }}
                  </p>
                  <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                    @{{ userProfile.username }}
                  </p>
                </div>
              </div>
              <p>
                {{ item.text }}
              </p>
            </div>
            <template #footer>
              <div class="flex items-center justify-between">
                <p class="text-sm text-ds-text-secondary">{{ formatDate(item.createdAt) }}</p>
                <div class="flex gap-x-1">
                  <new-button
                    size="sm"
                    type="transparent"
                    v-if="popularTweets.length"
                    icon
                    inactive
                    @click="nextTweet"
                  >
                    <inline-svg
                      src="/img/icons/new/chevron.svg"
                      class="rotate-180 transform rounded-full bg-ds-button-secondary-background stroke-main-color-100"
                  /></new-button>
                  <new-button
                    size="sm"
                    type="transparent"
                    v-if="popularTweets.length"
                    icon
                    @click="previousTweet"
                  >
                    <inline-svg
                      src="/img/icons/new/chevron.svg"
                      class="rounded-full bg-ds-button-secondary-background stroke-main-color-100"
                  /></new-button>
                </div>
              </div>
            </template>
          </tweet-variation-card>
        </transition-group>
        <div
          class="horizontal-scrollable-container row-span-3 flex w-full gap-x-4 overflow-x-auto pt-8 md:w-2/3 md:pb-16 md:pl-5"
        >
          <!-- the reason why we're just hiding the frame instead of removing it -->
          <!-- is because we need to keep the iframe player loaded in order to have a valid reference -->
          <!-- each time we need it -->
          <video-card
            v-for="(_, index) of Array.from({ length: 2 })"
            :key="index"
            :ref="`video-card`"
            class="flex-none"
            v-show="!hiddenFramesIndices.includes(index)"
          >
            <div class="relative h-full rounded-lg bg-gray-100">
              <div class="absolute flex items-start justify-between">
                <div>
                  <inline-svg src="/img/icons/socials/reels-icon.svg" class="h-6 w-6" />
                </div>
              </div>
              <iframe
                allowfullscreen
                :src="tweetReelPreviewURL"
                class="rounded-lg"
                :style="iframeStyle"
                :ref="`remotion-player-${index}`"
              />
            </div>
            <template #action>
              <fade-transition
                :duration="{
                  enter: 400,
                }"
              >
                <div
                  class="flex w-full items-center justify-between py-2"
                  v-show="isScheduleLoaded"
                >
                  <new-button size="sm" :disabled="!canUserApproveReel" @click="scheduleReel(index)">Approve</new-button>
                  <div class="flex gap-2">
                    <new-button size="sm" type="transparent" icon @click="openReelEditor(index)">
                      <inline-svg
                        src="/img/icons/write-icon.svg"
                        class="h-5 w-5 stroke-main-color-100"
                    /></new-button>
                    <new-button size="sm" type="transparent" icon @click="hideFrame(index)">
                      <inline-svg
                        src="/img/icons/trash-icon.svg"
                        class="fill-main-color-100 text-main-color-100"
                      />
                    </new-button>
                  </div>
                </div>
              </fade-transition>
            </template>
          </video-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import VideoCard from '../Cards/DashboardCards/VideoCard.vue';
  import TweetVariationCard from '../Cards/DashboardCards/TweetVariationCard.vue';
  import { Thread } from '../../models/Thread';
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';
  import moment from 'moment';
  import { mapGetters, mapState } from 'vuex';
  import { store } from '@/store';
  import lodash from 'lodash';
  import dao from '@/dao';
  import controller from '@/controller';
  import { EventBus } from '@/event-bus';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { FadeTransition } from 'vue2-transitions';

  const fb = require('../../firebase');
  const config = require('@/config');

  export default {
    name: 'TweetToVideo',

    props: {
      isScheduleLoaded: {
        type: Boolean,
        default: false,
      },
      canUserApproveReel: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      ...mapState(['schedule', 'userCategories']),
      formattedPopularTweets() {
        if (this.popularTweets.length === 0) {
          return [];
        }

        return this.popularTweets.map(this.threadDocToTweetObject);
      },
      iframeStyle() {
        if (!this.isMounted) {
          return '';
        }

        return {
          height: `${this.$refs['video-card'][0].$el.offsetHeight}px`,
          width: `${this.$refs['video-card'][0].$el.offsetWidth}px`,
        };
      },
      tweetReelPreviewURL() {
        return config.tweetReelPreviewURL;
      },
      isRemotionPlayersLoading() {
        return (
            // does not work properly in Cypress, so disabling
          this.remotionPlayers.every((player) => player.loading) && this.remotionPlayers.length && !window.Cypress
        );
      },
      videosLeft() {
        return 30 - this.userProfile.reels.renderCount;
      },
      isLoading() {
        return this.isRemotionPlayersLoading || this.isScheduling;
      },
    },
    async created() {
      this.fetchTopRepliedTweets();
      this.fetchTopRetweetedTweets();
      this.fetchRandomTweets();
      this.fetchTopLikedTweets();
      this.reelsAudio = (await dao.appSettings.fetchReelsAudio()).map((i) => ({
        url: config.buildStorageMediaURL(i.path),
        duration: i.duration,
      }));
      this.reelsVideo = (await dao.appSettings.fetchReelsVideo()).map((m) => ({
        url: config.buildStorageMediaURL(m.path),
        thumbnail: config.buildStorageMediaURL(m.thumbnail),
      }));
      this.$nextTick(() => {
        this.popularTweets.length && this.nextTweet();
      });
    },
    components: {
      VideoCard,
      TweetVariationCard,
      FadeTransition,
    },
    mounted() {
      this.isMounted = true;

      this.remotionPlayers = Object.keys(this.$refs)
        .filter((ref) => ref.startsWith('remotion-player'))
        .map((ref) => {
          return {
            player: this.$refs[ref][0],
            loading: true,
          };
        });
      const self = this;

      window.onmessage = function (e) {
        if (e.data == 'loaded') {
          self.remotionPlayers.forEach((ref) => {
            ref.loading = false;
          });
        }
      };
    },
    beforeDestroy() {
      this.listOfSnapshotsUnsubscriptions.forEach((unsubscribe) => {
        if (!unsubscribe) return;
        unsubscribe();
      });
    },
    data() {
      return {
        isScheduling: false,
        popularTweets: [],
        reelsAudio: [],
        reelsVideo: [],
        hiddenFramesIndices: [],
        selectedTweet: null,
        tweetReelsOptions: [],
        isMounted: false,
        tweetsState: 'idle',
        animationDirection: 'down',
        listOfSnapshotsUnsubscriptions: [],
        remotionPlayers: [],
        tweetCardsContainerHeight: 500,
      };
    },
    watch: {
      tweetReelsOptions: {
        handler(reelsOptions) {
          if (reelsOptions.length) {
            reelsOptions.forEach((reelOption, index) => {
              this.sendTweetToRemotionPlayer(reelOption, index);
            });
          }
        },
      },
      selectedTweet: {
        handler(tweet) {
          if (tweet) {
            this.tweetReelsOptions = this.generateRandomReels(this.threadDocToTweetObject(tweet));
          }
        },
      },
      isRemotionPlayersLoading: {
        handler(loading) {
          if (!loading) {
            const tweetCardRefs = this.$refs['tweet-card'];
            this.$nextTick(() => {
              if (tweetCardRefs) {
                this.tweetCardsContainerHeight = Math.max(
                  ...tweetCardRefs.map((ref) => ref.$el.offsetHeight)
                );
              }
            });
          }
        },
      },
      isLoading: {
        handler(loading) {
          loading ? this.$emit('start-loading') : this.$emit('stop-loading');
        },
        immediate: true,
      },
    },
    methods: {
      openReelEditor(reelsOptionIndex) {
        EventBus.$emit('open-new-composer', {
          post: this.selectedTweet.data(),
          selectedTab: 'reel-editor',
          reelOptions: this.tweetReelsOptions[reelsOptionIndex],
        });
        this.$eventStore.tweetToVideo('Edit');
      },
      async scheduleReel(index) {
        if (this.userProfile.reels.renderCount >= 30) {
          this.swalModal({
            title: 'Sorry',
            text: 'You have reached the limit of 30 rendered reels.',
            type: 'warning',
          });
          return;
        }
        this.$eventStore.tweetToVideo('Approve');

        this.isScheduling = true;
        const { tweet, options, docId } = this.tweetReelsOptions[index];
        const instagramSlot = this.schedule
          .getAllSlots()
          .find((slot) => slot.slotType === 'instagram');
        const thread = Thread.newThread({
          time: instagramSlot.time.toDate(),
          tweets: [
            {
              status: tweet.text,
              media: [],
            },
          ],
          user: this.userProfile.uid,
          source: 'instagram-post',
        });
        try {
          const result = await thread.saveToFirestore(
            this.currentUser,
            this.userProfile.uid,
            this.timezone,
            this.$eventStore
          );

          const renderTasks = [
            {
              tweet,
              options,
              instagramPost: {
                id: result.postId,
                postNow: false,
              },
              originalPostId: docId,
            },
          ];

          const { data } = await controller.renderThreads(this.currentUser, renderTasks);
          if (data.success) {
            this.$router.push('/queue');
          } else {
            this.swalModal({
              title: 'Sorry',
              text: data.message,
              type: 'warning',
            });
          }

          if (this.userProfile.customerStatus === 'none') {
            EventBus.$emit('open-upgrade-plan-modal', {
              planName: 'Starter',
              addition: 'and connect your Instagram',
              isUserScheduling: true,
            });
          }
        } catch (err) {
          console.error(err);
        }
        this.isScheduling = false;
      },
      generateRandomReels(tweet) {
        this.$nextTick();
        const formattedTweet = this.formatTweetData(tweet);
        return Array.from({ length: 2 }, () => {
          const randomAudioIndex = Math.floor(Math.random() * this.reelsAudio.length);
          const randomVideoIndex = Math.floor(Math.random() * this.reelsVideo.length);
          const randomAudio = this.reelsAudio[randomAudioIndex];
          const videoOptions = {
            videoUrl: this.reelsVideo[randomVideoIndex].url,
            backgroundType: 'video',
          };
          const audioOptions = {
            duration: randomAudio.duration,
            audio: {
              url: randomAudio.url,
              startFrom: 0,
              endAt: randomAudio.duration,
            },
          };
          return {
            docId: tweet.docId,
            tweet: formattedTweet,
            options: {
              animation: 'none',
              ...audioOptions,
              ...videoOptions,
            },
            status: 'ready',
          };
        });
      },
      sendTweetToRemotionPlayer(reelOption, index) {
        const { tweet, options, status } = reelOption;
        if (status === 'sent') return;
        const player = this.$refs[`remotion-player-${index}`]
          ? this.$refs[`remotion-player-${index}`][0]
          : null;
        if (!player) return;
        player.contentWindow.postMessage(
          {
            tweet,
            ...options,
          },
          this.tweetReelPreviewURL
        );
        this.tweetReelsOptions[index].status = 'sent';
      },
      formatTweetData(tweet) {
        return {
          user: {
            name: this.userProfile.name,
            username: this.userProfile.username,
            profile_image_url: this.userProfile.photoURL,
            verified: true,
            verified_type: 'blue',
          },
          public_metrics: {
            retweet_count: tweet.retweets,
            reply_count: tweet.replyCount,
            like_count: tweet.likes,
          },
          text: tweet.text,
          createdAt: tweet.createdAt,
          lang: 'en',
        };
      },
      formatDate(date) {
        return moment(date).format('h:mm A · MMM DD, YYYY');
      },
      async fetchTopRepliedTweets() {
        const unsubscribe = fb.threadsCollection
          .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
          .orderBy('replyCountOfTheFirstTweet', 'desc')
          .limit(10)
          .onSnapshot((doc) => {
            this.collectPopularTweets(doc.docs);
          });
        this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
        store.dispatch('addFirestoreListener', unsubscribe);
      },
      async fetchTopRetweetedTweets() {
        const unsubscribe = fb.threadsCollection
          .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
          .orderBy('retweetCountOfTheFirstTweet', 'desc')
          .limit(10)
          .onSnapshot((doc) => {
            this.collectPopularTweets(doc.docs);
          });
        this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
        store.dispatch('addFirestoreListener', unsubscribe);
      },
      async fetchTopLikedTweets() {
        const unsubscribe = fb.threadsCollection
          .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
          .orderBy('favoriteCountOfTheFirstTweet', 'desc')
          .limit(10)
          .onSnapshot((doc) => {
            this.collectPopularTweets(doc.docs);
          });
        this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
        store.dispatch('addFirestoreListener', unsubscribe);
      },
      async fetchRandomTweets() {
        try {
          const eligibleTweetsQuery = fb.threadsCollection
            .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
            .limit(200);

          const countSnapshot = await eligibleTweetsQuery.get();
          const count = countSnapshot.size;

          if (count === 0) {
            return;
          }

          const randomIndex = Math.floor(Math.random() * count);

          const randomDoc = countSnapshot.docs[randomIndex];

          const randomCreatedAt = randomDoc.data().created_at;

          const querySnapshot = await eligibleTweetsQuery
            .orderBy('created_at')
            .startAfter(randomCreatedAt)
            .limit(10)
            .onSnapshot((doc) => {
              this.collectPopularTweets(doc.docs);
            });

          this.listOfSnapshotsUnsubscriptions.push(querySnapshot);
          store.dispatch('addFirestoreListener', querySnapshot);
        } catch (error) {
          console.error('Error fetching random tweets: ', error);
        }
      },
      threadDocToTweetObject(doc) {
        const tweet = doc.data().tweets[0];
        const tweetReel = lodash.get(doc.data(), 'tweetReel', null);

        const categoriesRefs = doc.data().categories;
        const categories = this.userCategories.filter((category) => {
          if (!categoriesRefs) {
            return false;
          }
          const categoryIndex = categoriesRefs.findIndex((ref) => ref.id === category.id);
          return categoryIndex !== -1;
        });

        return {
          docId: doc.id,
          id: tweet.tweetId,
          text: tweet.status,
          categories,
          likes: doc.data().favoriteCountOfTheFirstTweet,
          retweets: doc.data().retweetCountOfTheFirstTweet,
          replyCount: doc.data().replyCountOfTheFirstTweet,
          createdAt: doc.data().created_at.toDate(),
          tweetReel,
        };
      },
      hideFrame(index) {
        this.hiddenFramesIndices.push(index);
      },
      nextTweet: lodash.debounce(function () {
        if (this.tweetsState === 'idle' && !this.isScheduling) {
          this.$eventStore.tweetToVideo('Next Tweet');
          this.animationDirection = 'down';
          this.hiddenFramesIndices = [];
          this.tweetsState = 'loading';
          const tweet = this.popularTweets.shift();
          this.selectedTweet = this.popularTweets[0];
          setTimeout(() => {
            this.popularTweets.push(tweet);
            this.tweetsState = 'idle';
          }, 250);
        }
      }, 300),
      previousTweet: lodash.debounce(function () {
        if (this.tweetsState === 'idle' && !this.isScheduling) {
          this.$eventStore.tweetToVideo('Previous Tweet');
          this.animationDirection = 'up';
          this.hiddenFramesIndices = [];
          this.tweetsState = 'loading';
          const tweet = this.popularTweets.pop();
          this.selectedTweet = tweet;
          setTimeout(() => {
            this.popularTweets.unshift(tweet);
            this.tweetsState = 'idle';
          }, 250);
        }
      }, 300),
      triggerRegenerateRandomReels() {
        if (!this.selectedTweet) return;
        this.tweetReelsOptions = this.generateRandomReels(
          this.threadDocToTweetObject(this.selectedTweet)
        );
      },
      collectPopularTweets(docs) {
        /**
         * Filters and processes documents to update the popularTweets array.
         *
         *   - The document must have a non-empty 'tweets' array.
         *   - remove long tweets.
         *   - remove tweets that has autoDM
         */
        const docIds = new Set(this.popularTweets.map((tweet) => tweet.docId));
        docs
          .filter((doc) => !docIds.has(doc.id))
          .filter((doc) => {
            const data = doc.data();
            if (!data.tweets || data.tweets.length === 0) {
              return false;
            }
            const tweet = data.tweets[0];
            const autoDm = lodash.get(data, 'autoDM', null);
            return tweet.status.length < 280 && autoDm === null;
          })
          .forEach((doc) => {
            this.popularTweets.push(doc);
          });
      },
    },
    mixins: [NewThreadMixin, SwalModalMixin],
  };
</script>
<style lang="scss" scoped>
  h2.dashboard-title text-ds-text-primary {
    @apply text-xl font-bold;
  }

  .loader {
    height: calc(100vh - 200px);
  }
</style>
