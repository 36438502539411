<template>
  <modal :show="show" @close="close" :class="[{ 'overlay--on': submitting }]">
    <div data-cy="schedule-repeat-overlay" class="overlay" v-if="submitting">
      <div class="loader">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>

    <div data-cy="schedule-repeat-header" slot="header" class="modal-title">
      Schedule multiple retweets
    </div>
    <div class="flex flex-col gap-x-4 md:flex-row md:items-center">
      <div class="flex-1">
        <div class="mb-4 font-medium">Retweet every day at...</div>
        <div class="date-picker mt-4 inline space-x-2 lg:mt-0">
          <inline-svg src="/img/icons/history.svg"></inline-svg>
          <base-input data-cy="schedule-repeat-time" type="time" v-model="timeForInput" />
        </div>
      </div>

      <div class="flex-1">
        <div class="mb-4 font-medium">Until...</div>
        <div class="date-picker mt-4 inline space-x-2 lg:mt-0">
          <inline-svg src="/img/icons/calendar.svg"></inline-svg>
          <base-input
            data-cy="schedule-repeat-date"
            type="date"
            v-model="dateForInput"
            :max="maxDateForInput"
            :min="minDateForInput"
          />
        </div>
      </div>
    </div>
    <div class="actions mt-6">
      <base-button
        data-cy="schedule-repeat-retweet"
        type="primary"
        :disabled="!isTimeValid() || !isDateLowerThan28Days()"
        :class="{ disabled: submitting }"
        @click="scheduleRetweet()"
        class="button primary-button small-button ml-auto"
      >
        <span>{{ recurrenceMessage }}</span>
      </base-button>
    </div>
  </modal>
</template>
<script>
  import moment from 'moment';
  import 'moment-timezone';
  import { mapGetters } from 'vuex';
  import { Retweet } from '@/models/Retweet';
  import ThreadMixin from '../Mixins/ThreadMixin';
  import { Thread } from '../../models/Thread';
  import { EventBus } from '@/event-bus';

  const SAFETY_SECONDS = 10;

  export default {
    name: 'schedule-retweets-recurrently-modal',
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      dateForInput: {
        get() {
          return this.date.format('YYYY-MM-DD');
        },
        set(dateForInput) {
          const split = dateForInput.split('-');
          this.date = moment.tz(`${split[0]}-${split[1]}-${split[2]}`, this.userProfile.timezone);
        },
      },
      maxDateForInput() {
        return moment.tz(this.userProfile.timezone).add(28, 'day').format('YYYY-MM-DD');
      },
      minDateForInput() {
        return moment.tz(this.userProfile.timezone).format('YYYY-MM-DD');
      },
      recurrenceMessage() {
        if (!this.datetime) return;
        const { diffInSeconds, diffInDays } = this.getDaysDifference();
        if (diffInSeconds < SAFETY_SECONDS) return 'Please select a time in the future.';
        if (diffInSeconds < 24 * 3600) return 'Retweet once';
        if (diffInSeconds < 48 * 3600) return 'Retweet twice';
        if (!this.isDateLowerThan28Days()) {
          return 'Please select a day not farther than 28 days';
        }
        return isNaN(diffInDays) ? 'Retweet' : 'Retweet ' + diffInDays + ' times';
      },
      timeForInput: {
        get() {
          const { hours, minutes } = this.time;
          return `${this.padNumber(hours, 2)}:${this.padNumber(minutes, 2)}:00`;
        },
        set(timeForInput) {
          const split = timeForInput.split(':');
          this.time = { hours: parseInt(split[0]), minutes: parseInt(split[1]) };
        },
      },
    },
    created() {
      this.time = { hours: 10, minutes: 10 };
      const now = moment.tz(this.userProfile.timezone);
      const date = moment.tz(this.userProfile.timezone);
      date.set(this.time);
      const diffSeconds = date.diff(now, 'seconds');
      const daysToAdd = diffSeconds > 0 ? 6 : 7; // Depending on whether "now" is before or
      // after 10:10, the number of days that must
      // be added is different
      date.add(daysToAdd, 'days');
      this.date = date;
    },
    data() {
      return this.initialState();
    },
    methods: {
      close() {
        this.$emit('close');
      },
      forceRefreshSubmitButton() {
        const datetime = this.datetime;
        this.$set(this.datetime, null);
        this.$set(this.datetime, datetime);
      },
      initialState() {
        return {
          time: null,
          date: null,
          datetime: null,
          submitting: false,
        };
      },
      isTimeValid() {
        if (!this.datetime) return;
        const { diffInSeconds } = this.getDaysDifference();
        return diffInSeconds > SAFETY_SECONDS;
      },
      isDateLowerThan28Days() {
        if (!this.datetime) return;
        const { diffInDays } = this.getDaysDifference();
        return diffInDays <= 28;
      },
      getDaysDifference() {
        if (!this.datetime) return;
        const now = moment.tz(this.userProfile.timezone);
        const diffInSeconds = this.datetime.diff(now, 'seconds');
        const diffInDays = parseInt(diffInSeconds / 24 / 3600 + 1);
        return { diffInSeconds, diffInDays };
      },
      padNumber(num, size) {
        return ('000000000' + num).substr(-size);
      },
      resetModal() {
        Object.assign(this.$data, this.initialState());
      },
      scheduleRetweet() {
        const isThread = this.post instanceof Thread;

        if (!this.isTimeValid()) {
          this.forceRefreshSubmitButton();
          return;
        }

        this.submitting = true;

        const { diffInDays } = this.getDaysDifference();

        const times = [];
        for (let i = diffInDays - 1; i >= 0; i--) {
          const time = this.datetime.clone();
          time.add(-1 * i, 'days');
          times.push(time);
        }

        const originalTweetInfo = {
          username: this.userProfile.username,
          userDisplayName: this.userProfile.name,
          userProfilePictureURL: this.userProfile.photoURL,
          userTwitterId: this.userProfile.twitterId,
          text: isThread ? this.post.tweets[0].status : this.post.originalTweetInfo.text,
        };

        const retweets = times.map((time) =>
          Retweet.newRetweet(
            isThread ? this.post.tweetIds[0] : this.post.tweetId,
            time,
            this.userProfile,
            originalTweetInfo
          )
        );

        const promises = retweets.map((r) => r.saveToFirestore(this.$eventStore));

        return Promise.all(promises)
          .then(() => {
            this.close();
            this.resetModal();
            this.$notify({ type: 'success', message: 'Retweets successfully scheduled.' });

            if (this.userProfile.customerStatus === 'none') {
              EventBus.$emit('open-upgrade-plan-modal', {
                planName: 'Starter',
                isUserScheduling: true,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            alert('An error has occurred while scheduling the retweet.');
          })
          .finally(() => {
            this.submitting = false;
          });
      },
      updateDatetime() {
        const datetime = moment.tz(this.date, this.userProfile.timezone);
        datetime.set(this.time);
        this.datetime = datetime;
      },
    },
    mixins: [ThreadMixin],
    props: {
      show: Boolean,
      post: {
        type: Object,
      },
    },
    watch: {
      date: function () {
        this.updateDatetime();
      },
      time: function () {
        this.updateDatetime();
      },
    },
  };
</script>
<style>
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
</style>
