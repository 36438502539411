<template>
  <div class="mb-6 flex gap-2 rounded-md bg-ds-foreground p-4">
    <inline-svg
      src="img/icons/categories-icon.svg"
      class="h-6 w-6 stroke-current text-ds-button-icon"
    />
    <span class="flex flex-col gap-2">
      <p class="text-sm font-semi-medium text-ds-button-icon">Upgrade to Unlock Custom Layouts</p>
      <p class="text-sm font-semi-medium text-ds-button-primary-hover">
        Customization is available for users with a Creator plan or above
      </p>
      <div>
        <new-button size="sm">Upgrade</new-button>
      </div>
    </span>
    <inline-svg
      src="img/icons/close.svg"
      class="h-6 w-6 cursor-pointer text-ds-button-icon"
      @click="close"
    />
  </div>
</template>

<script>
  export default {
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
